import React, { useState, useEffect } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import { useAuth } from "../../contexts/AuthContext";
import { loadStripe } from '@stripe/stripe-js';
import { database } from '../../firebase';
import Individual from './Individual';
import Enterprise from './Enterprise';
import { useHistory } from "react-router-dom";
import StudentsEducators from './StudentsEducators';
import UserNotLoggedInModal from './UserNotLoggedInModal'
import './css/Sales.css';
import Logo from '../../assets/Logo.png';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const formatPrice = (amount) => {
  return (amount / 100).toFixed(2);
};

const Sales = () => {
  const { currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState('individual');
  const [userNotLoggedInModal, setUserNotLoggedInModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false); // State to track loading
  const [clickedPriceId, setClickedPriceId] = useState(null); // State to track clicked priceId
  const [userSubscription, setUserSubscription] = useState(null); // State to track user's subscription

  const handleClose = () => setUserNotLoggedInModal(false);
  const handleClickNotLogIn = () => setUserNotLoggedInModal(true);
  const history = useHistory();

  const fetchProducts = async () => {
    try {
      const productsSnapshot = await database.products.where('active', '==', true).get();
      const productsData = [];

      for (const product of productsSnapshot.docs) {
        const productData = product.data();
        const priceSnap = await product.ref.collection('prices').get();
        const prices = [];

        priceSnap.forEach((priceDoc) => {
          const priceData = priceDoc.data();
          const formattedPrice = formatPrice(priceData.unit_amount);
          const interval = priceData.interval_count === 1 ? 'monthly' : 'sixMonths';

          prices.push({
            priceId: priceDoc.id,
            amount: formattedPrice,
            currency: priceData.currency,
            interval: interval
          });
        });

        productsData.push({
          id: product.id,
          name: productData.name,
          description: productData.description,
          prices: prices
        });
      } 
      console.log(productsData)
      setProducts(productsData);

      // Fetch user's subscription
      if (currentUser) {
        const subscriptionSnapshot = await database.customers.doc(currentUser.uid).collection('subscriptions').where('status', '==', 'active').get();
        console.log(subscriptionSnapshot)
        if (!subscriptionSnapshot.empty) {
          console.log(subscriptionSnapshot.docs[0].data())
          setUserSubscription(subscriptionSnapshot.docs[0].data());
        }
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleDownloadClick = async() => {
    if (!currentUser) {
      handleClickNotLogIn();
      return;
    }
    history.push("/profile");
  }

  const handleClickForBuying = async (priceId, mode, subscriptionType) => {
    if (!currentUser) {
      handleClickNotLogIn();
      return;
    }
  
    setLoading(true); // Set loading state to true
    setClickedPriceId(priceId);
  
    try {
      // Create a Checkout Session
      const docRef = await database.customers.doc(currentUser.uid).collection('checkout_sessions').add({
        price: priceId,
        success_url: process.env.REACT_APP_STRIPE_SUCCESS_URL,
        cancel_url: process.env.REACT_APP_STRIPE_CANCEL_URL,
        mode: mode,
        metadata: { subscriptionType: subscriptionType },
      });
  
      // Set up a Real-time Listener: A real-time listener (onSnapshot) is set up to monitor changes to the newly created checkout session document in Firestore.
      docRef.onSnapshot(async (snap) => {
        // Cloud Function will update the session document with the sessionId once it has been created by Stripe.
        const { error, sessionId } = snap.data();
        
        if (error) {
          alert(`An error occurred: ${error.message}`);
          setLoading(false); // Reset loading state on error
        } else if (sessionId) {
          const stripe = await stripePromise;
          // Redirect to Stripe Checkout: Once the session document contains the sessionId, the frontend code uses the Stripe library to redirect the user to the Stripe Checkout page using this sessionId.
          stripe.redirectToCheckout({ sessionId });
          // Do not set loading state to false here, as the redirection will handle the UI transition
        }
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setLoading(false); // Reset loading state on error
      setClickedPriceId(null); // Reset the clicked priceId
    }
  };
  

  const [fade, setFade] = useState(true);

  const handleTabClick = (tab) => {
    setFade(true);
    setTimeout(() => {
      setActiveTab(tab);
      window.location.hash = `#${tab}`;
    }, 500);
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      if (['individual', 'enterprise', 'schooling'].includes(hash)) {
        setActiveTab(hash);
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>Pricing - Tensor Pilot</title>

        {/* Meta Description for SEO */}
        <meta name="description" content="Choose the Tensor Pilot plan that's right for you. We offer budget-friendly packages for AI-driven drone software, with options for individuals, enterprises, and educators." />

        {/* SEO Keywords */}
        <meta name="keywords" content="Tensor Pilot, AI Drone Software Pricing, Drone AI Software, Tensor Pilot Pricing, Drone Software Plans, AI Drone, Drone AI Subscription" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Tensor Pilot Pricing - AI Drone Software" />
        <meta property="og:description" content="Explore Tensor Pilot's affordable AI Drone Software packages. Ideal for individuals, enterprises, and educators. Download the free version today or subscribe to unlock advanced features." />
        <meta property="og:url" content="https://www.aidronesoftware.com/sales" />
        <meta property="og:site_name" content="Tensor Pilot - AI Drone Software" />
        <meta property="og:image" content={Logo} /> 
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Pricing - Tensor Pilot an AI Drone Software" />
        <meta name="twitter:description" content="Choose the best plan for AI Drone Software with Tensor Pilot. Explore our free version, or subscribe for advanced features." />
        <meta name="twitter:image" content={Logo} /> 

        {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <NavBarMain />
      <Container>
        <div className="Sales">
          <center><h3 className='sales-page-title'>Tensor Pilot Pricing</h3></center>
          <center><h5>Choose the plan that's right for you</h5></center>
          <p className="text-center">We encourage our users to install the free software before buying the pro subscription. Please feel free to reach us out to request new features, engage in discussion, and join the drone community.</p>

          <nav className="nav nav-pills flex-column flex-sm-row justify-content-center mt-4">
            <a
              className={`flex-sm-fill text-sm-center nav-link ${activeTab === 'individual' ? 'active disabled' : ''}`}
              href="#individual"
              onClick={(e) => {
                if (activeTab === 'individual') {
                  e.preventDefault();
                } else {
                  handleTabClick('individual');
                }
              }}
            >
              Individual
            </a>
            <a
              className={`flex-sm-fill text-sm-center nav-link ${activeTab === 'enterprise' ? 'active disabled' : ''}`}
              href="#enterprise"
              onClick={(e) => {
                if (activeTab === 'enterprise') {
                  e.preventDefault();
                } else {
                  handleTabClick('enterprise');
                }
              }}
            >
              Enterprise
            </a>
            <a
              className={`flex-sm-fill text-sm-center nav-link ${activeTab === 'schooling' ? 'active disabled' : ''}`}
              href="#schooling"
              onClick={(e) => {
                if (activeTab === 'schooling') {
                  e.preventDefault();
                } else {
                  handleTabClick('schooling');
                }
              }}
            >
              Students & Educators
            </a>
          </nav>


          <div className={`mt-5 mb-5 tab-content ${fade ? 'tab-content-active' : ''}`}>
            {activeTab === 'individual' && (
                <Individual 
                  products={products} 
                  handleClickForBuying={handleClickForBuying}
                  handleDownloadClick={handleDownloadClick}
                  loading={loading} 
                  clickedPriceId={clickedPriceId} // Pass clickedPriceId to Individual component
                  userSubscription={userSubscription}
                />
            )}

            {activeTab === 'enterprise' && <Enterprise />}

            {activeTab === 'schooling' && <StudentsEducators />}
          
          </div>

        </div>
      </Container>
      
      <UserNotLoggedInModal 
        show={userNotLoggedInModal}
        handleClose={handleClose}
      />
    </>
  );
};

export default Sales;
