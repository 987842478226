import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Logo from '../../assets/Logo.png';

import './Navbar.css'; // Adjust path as necessary

const NavbarMain = (props) => {
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    try {
      await logout();
      history.push("/login");
    } catch (error) {
      console.error("Failed to log out", error);
    }
  }

  return (
    <Navbar 
      className="px-4"
      variant={props.variant} 
      expand="lg"
    >
      <Navbar.Brand href="/" className="navbar-brand">
          <img 
            className="navbar-logo"
            src={Logo} 
            alt="Drone AI Logo"
          />
          <p className="navbar-brand-text">Tensor Pilot</p>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/" className="nav-link"><b>Home</b></Nav.Link>
          <Nav.Link href="/sales" className="nav-link"><b>Pricing</b></Nav.Link>
          <Nav.Link href="/blog" className="nav-link"><b>Blog</b></Nav.Link> {/* Blog Link */}
          {/* <Nav.Link href="/education" className="nav-link"><b>Education</b></Nav.Link> */}
          {/* <Nav.Link href="/enterprise" className="nav-link"><b>Enterprise</b></Nav.Link>*/}
          <Nav.Link href="/contactus" className="nav-link"><b>Support</b></Nav.Link> 
          
          {currentUser && currentUser.email ? (
            <>
              <Nav.Link href="/profile" className="nav-link"><b>Profile</b></Nav.Link>
              <Nav.Link id="logging" onClick={handleLogout} className="nav-link">
                <button type="button" className="btn btn-outline-primary "><b>Sign Out</b></button>
              </Nav.Link>
            </>
          ) : (
            <Nav.Link id="logging" href="/login" className="nav-link">
                <button type="button" className="btn btn-outline-primary "><b>Sign In</b> </button>
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarMain;
