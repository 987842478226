import React from "react";
import { Helmet } from 'react-helmet';
import { Container, Card, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import NavBarMain from '../../sharedcomponents/navbar/Navbar';

const PaymentFailed = () => {
  const history = useHistory();

  const handleGoToProfile = () => {
    history.push("/profile");
  };

  const handleGoToSales = () => {
    history.push("/sales");
  };

  const handleContactUs = () => {
    history.push("/contactus");
  };

  return (
    <>
      <Helmet>
        <title>Sales - AI Drone Software</title>
        <meta name="description" content="Subscribe for our budget-friendly packages for the AI Drone Software, today." />
        <meta name="keywords" content="Drone AI Software, Drone Software, AI Drone, Drone AI Software Subscribe, Drone AI Software Buy" />
        <meta property="og:title" content="Sales - AI Drone Software" />
        <meta property="og:description" name="description" className="swiftype" content="Subscribe for our budget-friendly packages for the AI Drone Software, today." />
        <meta property="og:url" content="https://www.aidronesoftware.com/sales" />
        <meta property="og:site_name" content="Sales - AI Drone Software" />
      </Helmet>
      <NavBarMain />
      <Container
        className="" style={{ minHeight: "80vh" }}
      >
        <div className="">
          <Card className="text-center border-0 shadow-sm">
            <Card.Body>
              <h3 className="mb-4">Oops, Payment Failed :(</h3>
              <p className="mb-4">We're sorry, but your payment couldn't be processed. Please try again or visit your profile for more options.</p>
              <Button variant="outline-primary" onClick={handleGoToSales}>
                Try Again
              </Button>
              <Button variant="outline-primary mx-2" onClick={handleGoToProfile}>
                Go to Profile
              </Button>

              <Button variant="outline-primary" onClick={handleContactUs}>
                Contact Us
              </Button>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default PaymentFailed;
