import { Card, Button, Spinner, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindows, faApple } from '@fortawesome/free-brands-svg-icons';
import { useState } from 'react';
import useSyncState from '../../sharedcomponents/syncState';
import { database, functions } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import './css/Subscription.css';  // Import the CSS file with gradient styles
import Logo from '../../assets/Logo.png';
import MsftInstall1 from './assets/MsftInstall1.png';
import MsftInstall2 from './assets/MsftInstall2.png';
import MsftInstall3 from './assets/MsftInstall3.png';
import MsftInstall4 from './assets/MsftInstall4.png';
import MacInstall1 from './assets/MacInstall1.png';


library.add(faDownload, faWindows, faApple);

const Subscription = ({ userSubscription }) => {
    const { currentUser } = useAuth();
    
    const [isLoading, setIsLoading] = useState(false);  // State for loading
    const [showMacModal, setShowMacModal] = useState(false);  // State for Mac modal
    const [showWindowsModal, setShowWindowsModal] = useState(false);  // State for Windows modal
    
    const redirectToCustomerPortal = async () => {
        setIsLoading(true);  // Set loading state to true
        try {
            const functionRef = functions.httpsCallable('ext-firestore-stripe-payments-createPortalLink');
            const { data } = await functionRef({ returnUrl: process.env.REACT_APP_STRIPE_PROFILE_URL });
            window.location.assign(data.url);
        } catch (error) {
            console.error("Error redirecting to customer portal: ", error);
        } finally {
            setIsLoading(false);  // Reset loading state
        }
    };

    const handleDownload = async (platform, arch) => {
        
        try {
            // setIsLoading(true);
            const response = await fetch('/api/download', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'Platform': platform,
                    'Arch': arch
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const { url } = await response.json();
            
            // Create a temporary anchor element to trigger the download
            const a = document.createElement('a');
            a.href = url;
            a.download = ''; // You can set a default filename here if needed
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            
            if (platform === 'Mac') {
                setShowMacModal(true);
                return;
            }
            
        } catch (error) {
            console.error("Error downloading the file: ", error);
        } finally {
            // setIsLoading(false);
        }
    }

    // Check subscription status and prepare the message
    let statusMessage = '';
    switch (userSubscription?.status) {
        case 'active':
            statusMessage = 'Your subscription is active.';
            break;
        case 'past_due':
            statusMessage = 'Your subscription payment is past due.';
            break;
        case 'canceled':
            statusMessage = 'Your subscription has been canceled.';
            break;
        case 'incomplete':
            statusMessage = 'Your subscription is incomplete and requires payment details.';
            break;
        case 'incomplete_expired':
            statusMessage = 'Your incomplete subscription has expired.';
            break;
        case 'trialing':
            statusMessage = 'Your subscription is currently in a trial period.';
            break;
        case 'unpaid':
            statusMessage = 'Your subscription payment has failed.';
            break;
        default:
            statusMessage = '';
    }

    return (
        <>
            <Card className="border-0 shadow-sm">
                <Card.Header className="bg-primary text-white d-flex justify-content-center align-items-center">
                    <h5 className="mb-0">Download Software</h5>
                </Card.Header>
                <Card.Body className="text-center">
                    <div className="d-flex flex-column align-items-center">
                        <div className="d-flex justify-content-between mb-4" style={{ width: '100%', maxWidth: '500px' }}>
                            <Button
                                variant="outline-primary"
                                onClick={() => handleDownload("Mac", "intel")}
                                style={{ flex: 1, marginRight: '10px' }}
                            >
                                <FontAwesomeIcon icon={['fab', 'apple']} /> Mac OS (Intel) <FontAwesomeIcon icon={faDownload} />
                            </Button>

                            <Button
                                variant="outline-primary"
                                onClick={() => handleDownload("Mac", "arm")}
                                style={{ flex: 1, marginRight: '10px' }}
                            >
                                <FontAwesomeIcon icon={['fab', 'apple']} /> Mac OS (ARM) <FontAwesomeIcon icon={faDownload} />
                            </Button>

                        </div>
                        <div className="d-flex justify-content-between" style={{ width: '100%', maxWidth: '500px' }}>
                            <Button
                                variant="outline-primary"
                                onClick={() => window.open("https://apps.microsoft.com/store/detail/9P7MRSZSCWNN?cid=DevShareMCLPCS", "_blank")}
                                style={{ flex: 1, marginRight: '10px' }}
                            >
                                <FontAwesomeIcon icon={['fab', 'windows']} /> Windows Store <FontAwesomeIcon icon={faDownload} />
                            </Button>
                            {/* <Button
                                variant="outline-secondary"
                                onClick={() => setShowWindowsModal(true)}
                                style={{ flex: 1, marginLeft: '10px' }}
                            >
                                <FontAwesomeIcon icon={['fab', 'windows']} /> Installation Guide
                            </Button> */}
                        </div>
                    </div>
                    <p className="styled-paragraph">
                        Compatible with Dji Tello Drones, if you would like to use Tensor Pilot with your custom drone, please <a href="/contactus">Contact Us</a>
                    </p>
                </Card.Body>
            </Card>



            { userSubscription ? (
                <Card className="border-0 shadow-sm mt-3">
                    <Card.Header className="bg-primary text-white d-flex justify-content-center align-items-center">
                        <h5>Subscription & Billing</h5>
                    </Card.Header>
                    <Card.Body className="text-center">
                        {statusMessage && <p>{statusMessage}</p>}
                        <Button
                            variant="outline-primary"
                            onClick={redirectToCustomerPortal}
                            disabled={isLoading}
                        >
                            {isLoading ? <Spinner animation="border" size="sm" /> : "Manage Subscription"}
                        </Button>
                    </Card.Body>
                </Card>
            ):( 
                <div className="upgrade-box mt-3">
                    <h5>Upgrade to Pro</h5>
                    <p>Unlock advanced features and get the most out of Tensor Pilot. Upgrade to Pro version for enhanced capabilities and priority support.</p>
                    <Button 
                        variant="outline-light"
                        as={Link} to="/sales"
                    >
                        <b>Upgrade</b>
                    </Button>
                </div>
            )}

            {/* Mac Installation Guide Modal */}
            <Modal show={showMacModal} size="lg" onHide={() => setShowMacModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Tensor Pilot Mac OS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>👋 Hej!
                    <br/><br/>
                    Thank you for downloading the Tensor Pilot Mac Application, we will love to hear back from you, please contact us on support@aidroneosoftware.com.
                    </p>
                    <ol>
                        <li>Once the Tensor Pilot .dmg is downloaded, open the file, drag the app to the Applications folder, then double-click to open it. When the Mac warning appears stating "Tensor Pilot is an app downloaded from the Internet. Are you sure you want to open it?", click "Open" to proceed.</li>
                        <img src={MacInstall1} alt="" width="30%" />
                    </ol>
                    
                    <br/><br/>
                    <p> - AI Drone Software Team</p>
                    <img 
                        className="navbar-logo"
                        src={Logo} 
                        alt="Drone AI Logo"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowMacModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => { setShowMacModal(false);}}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Windows Installation Guide Modal */}
            <Modal show={showWindowsModal} size="lg" onHide={() => setShowWindowsModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Windows Installation Guide</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>👋 Hej!
                    <br/><br/>
                    Please follow these steps to open the app for the first time:
                    </p>
                    <p>
                        Microsoft Defender SmartScreen is designed to protect your PC from unrecognized and potentially unsafe applications. When an application is not commonly downloaded, it may trigger a warning to ensure the safety of your system. While Tensor Pilot-1.0.0 Setup.exe is safe, it is not widely recognized by Microsoft yet. The following steps will guide you on how to bypass these warnings and safely run the application.
                        <br/><br/>
                        You only need to do these steps only once, till Step 3.
                    </p>
                    <h5>Step 1: Initial Warning</h5>
                    
                    <img src={MsftInstall1} alt="Initial warning from Microsoft Defender SmartScreen" width="30%" />
                    <p className='mb-4'>Figure 1.1: Initial warning from Microsoft Defender SmartScreen. Click on ellipsis (3 horizontal dots) to open up the child menu.</p>
                    
                    <img src={MsftInstall2} alt="Click on ‘Keep’ to save the exe" width="30%" />
                    <p className='mb-4'>Figure 1.2: Click on ‘Keep’ to save the exe to the ‘Downloads’ Folder.</p>
                    
                    <h5>Step 2: SmartScreen Warning</h5>
                    <p>This is the second warning from Windows. Please see how to accept it.</p>
                    
                    <img src={MsftInstall3} alt="SmartScreen second warning" width="30%" />
                    <p className='mb-4'>Figure 2.1 and 2.2: SmartScreen second warning while opening the file. Click on ‘Show More’ > ‘Keep anyway’</p>
                    
                    <h5>Step 3: Final Confirmation Dialog</h5>
                    <p>Open the ‘Tensor Pilot-1.0.0 Setup” from Downloads folder. This is the last time Windows will warn you about the application, choose ‘Run anyway’</p>
                    
                    <img src={MsftInstall4} alt="Final confirmation dialog" width="60%" />
                    <p className='mb-4'>Figure 3: Final acknowledges Windows is starting an unrecognized app. Choose ‘Run anway’ to accept the Unknown Publisher Warning.</p>
                    
                    <h5>Conclusion</h5>
                    <p>
                        By following these steps, you can bypass the warnings and safely install Tensor Pilot-1.0.0. Rest assured, our software is secure and has been thoroughly tested. We are in the process of getting it recognized by Microsoft to prevent such warnings in the future. Apologies for any inconvenience.
                        <br/><br/>
                        If you have any questions, please reach us out on support@aidroneosoftware.com
                        <br/><br/>
                        Thanks,<br/>
                        Tensor Pilot Engineering
                    </p>
                    <img 
                        className="navbar-logo"
                        src={Logo} 
                        alt="Drone AI Logo"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowWindowsModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => { setShowWindowsModal(false);}}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    );
};

export default Subscription;
