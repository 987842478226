import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './css/SubscriptionCard.css';

library.add(faCheckCircle, faTimesCircle);

const SubscriptionCardInfo = ({ title, description,features, onButtonClick, buttonText, popular }) => (
  <Card className="shadow-sm glassmorphic-card">
    <Card.Header className={`bg-primary text-white ${popular ? 'position-relative' : ''}`}>
      {popular && <div className="badge bg-danger position-absolute" style={{ top: '10px', right: '10px' }}>Most Popular</div>}
      <h5>{title}</h5>
      <p>{description}</p>
    </Card.Header>
    <Card.Body>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className={`feature-item ${feature.available ? 'available' : 'unavailable'}`}>
            <FontAwesomeIcon className="feature-icon" icon={feature.iconName} />
            <span className="feature-text">{feature.text}</span>
            <FontAwesomeIcon className="status-icon ml-1" icon={feature.available ? faCheckCircle : faTimesCircle} />
          </div>
        ))}
      </div>
    </Card.Body>
    <Card.Footer className="bg-transparent">
      <center>
        <Button
          variant={popular ? 'outline-primary' : 'primary'}
          className="custom-button m-2"
          onClick={onButtonClick}
        >
          <b>{buttonText}</b>
        </Button>
      </center>
    </Card.Footer>
  </Card>
);

export default SubscriptionCardInfo;
