import React, { useRef, useState } from "react";
import { Row, Col, Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import Helmet from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";
import LogoImg from '../../assets/Logo-HighRes.png';
import './ForgotPassword.css';
import Logo from '../../assets/Logo.png';

export default function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [token, setToken] = useState("");
  const reCaptchaRef = useRef();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    if (validateEmail(email)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const handleRecaptchaChange = (token) => {
    if (!token) {
      setError("Please complete the reCAPTCHA");
      setEmailValid(false);
    } else {
      setError("");
      setToken(token);
      setEmailValid(true);
    }
  };

  const handleRecaptchaExpired = () => {
    setToken("");
    reCaptchaRef.current.reset();
    setEmailValid(false);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (!emailValid || !token) {
      setError("Please enter a valid email address and complete the reCAPTCHA");
      return;
    }

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage("Check your inbox for further instructions");
    } catch {
      setError("Failed to reset password");
    }

    setLoading(false);
  }

  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>Forgot Password - Tensor Pilot</title>

        {/* Meta Description for SEO */}
        <meta name="description" content="Reset your password to regain access to Tensor Pilot AI Drone Software and continue exploring advanced drone features." />

        {/* SEO Keywords */}
        <meta name="keywords" content="Forgot Password, Password Reset, Recover Account, Reset Tensor Pilot Password, Drone Software Password Reset, AI Drone Software Password, Access Recovery, Tensor Pilot Account, AI Drone Software, Drone Software Recovery" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Forgot Password - Tensor Pilot AI Drone Software" />
        <meta property="og:description" content="Forgot your password? Reset it here to regain access to Tensor Pilot AI Drone Software and manage your drone operations." />
        <meta property="og:url" content="https://www.aidronesoftware.com/forgot-password" />
        <meta property="og:site_name" content="Tensor Pilot - AI Drone Software" />
        <meta property="og:image" content={Logo} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Forgot Password - Tensor Pilot an AI Drone Software" />
        <meta name="twitter:description" content="Reset your password to regain access to Tensor Pilot AI Drone Software and continue managing your drone features." />
        <meta name="twitter:image" content={Logo} />

        {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <NavBarMain />
      <div className="full-bg">
        <Row className="justify-content-md-center">
          <Col xs={12} sm={12} md={6} lg={6} xl={4} xxl={2}>
            <div className="hero">
              <center>
                <h4 className="hero__headline mb-4">Password Reset</h4>
              </center>


              {error && <Alert variant="danger">{error}</Alert>}
              {message && <Alert variant="success">{message}</Alert>}
              <Form onSubmit={handleSubmit} className='mt-2 form-css'>
                <Form.Group id="email">
                  <Form.Label><b>Email</b></Form.Label>
                  <Form.Control
                    type="email"
                    ref={emailRef}
                    required
                    placeholder="example@gmail.com"
                    className="p-2 rounded"
                    onChange={handleEmailChange}
                  />
                </Form.Group>
                <div className="d-flex justify-content-center mb-3">
                  <ReCAPTCHA
                    styleName={{ maxWidth: "100%" }}
                    ref={reCaptchaRef}
                    sitekey="6LcjnS4bAAAAAI3HKCTce9dAnOucKEalagR65dtr"
                    onChange={handleRecaptchaChange}
                    onExpired={handleRecaptchaExpired}
                  />
                </div>
                <Button
                  disabled={loading || !emailValid || !token}
                  className="w-100 mt-3 hero__button"
                  type="submit"
                >
                  Reset Password
                </Button>
              </Form>

              <div className="w-100 text-center mt-4">
                <Link to="/login" className="btn-link">
                  Login
                </Link>
              </div>
              <div className="w-100 text-center mt-3">
                Need an account?{" "}
                <Link to="/signup" className="btn-link">
                  Sign Up
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
