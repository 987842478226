import React from "react"
import { useState, useEffect} from 'react' 
import { Container, Card } from "react-bootstrap"
import { Link, useHistory, useParams, useLocation } from "react-router-dom"
import Confetti from 'react-confetti'
import NavBarMain from '../../sharedcomponents/navbar/Navbar'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDownload} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {database} from '../../firebase'
import { useAuth } from "../../contexts/AuthContext"

library.add(faDownload)

const PaymentSuccess = (props) => {

  // Hook
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }
  
  const [error, setError] = useState("")
  const { width, height } = useWindowSize()
  const { currentUser } = useAuth()
  const { query, search } = useLocation(); 
    
  useEffect(() =>{
    setError("")
    // subscription type
    const promises = []
    promises.push(database.users.doc(currentUser.uid).update({
        subscriptiontype:new URLSearchParams(search).get('subscriptiontype')
    }))
    
   Promise.all(promises)
    .then(() => {})
    .catch((err) => {
      setError("Failed to update account"+err.message)
    })
    .finally(() => {})
  },[])

  return (
    <>
      <Confetti
      width={width}
      height={height}
      />

      <NavBarMain />
      <Container
        className="d-flex justify-content-center" style={{ minHeight: "100vh" }}
      >
        <div className="w-100" style={{ marginTop:"10%" }}>
          <Card className="border-0">
            <Card.Body>
              {error==""?
                <center>
                  <h2 style={{"color":"#3b5998"}}>Congratulations !!</h2>
                  <br />
                  <p>You have just upgraded your drone & your pilot skils.</p>
                  <p>Lets get Eli installed on your machine. Please download Eli Drone software from your Profile</p>
                  <Link to='/profile'><FontAwesomeIcon icon="download" size="2x"/></Link>
                </center>
              :
                <center>{error}</center>
              }
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  )
}

export default PaymentSuccess