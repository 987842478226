import React from 'react';
import { Container, Card } from 'react-bootstrap';
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import Helmet from 'react-helmet';
import './css/TermsAndConditions.css';
import Logo from '../../assets/Logo.png';

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>Terms and Conditions - Tensor Pilot by AI Drone Software</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="Read the Terms and Conditions for Tensor Pilot by AI Drone Software. Understand your rights and responsibilities when accessing and using our AI drone software."
        />

        {/* SEO Keywords */}
        <meta
          name="keywords"
          content="Terms and Conditions, AI Drone Software Terms, Tensor Pilot Terms, Drone Software Legal, User Agreement, Software Usage Terms, Drone Software Policy, Terms of Service, AI Drone Legal, User Rights, Drone Software License"
        />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Terms and Conditions - Tensor Pilot by AI Drone Software" />
        <meta
          property="og:description"
          content="Review the Terms and Conditions for Tensor Pilot by AI Drone Software. Learn about the policies governing the use of our AI drone software and your responsibilities as a user."
        />
        <meta property="og:url" content="https://www.aidronesoftware.com/terms-and-conditions" />
        <meta property="og:site_name" content="Tensor Pilot by AI Drone Software" />
        <meta property="og:image" content={Logo} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Terms and Conditions - Tensor Pilot by AI Drone Software"
        />
        <meta
          name="twitter:description"
          content="Review the Terms and Conditions for Tensor Pilot by AI Drone Software, including policies and user responsibilities."
        />
        <meta name="twitter:image" content={Logo} />

        {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <NavBarMain />
      <Container className="terms-container">
        <Card className="terms-card">
          <Card.Body>
            <Card.Title>
              <h2>Terms and Conditions</h2>
            </Card.Title>
            <Card.Text>
              <h5>1. Introduction</h5>
              <p>
                Welcome to Tensor Pilot by AI Drone Software ("we", "our", or "us"). These Terms and Conditions govern your use of our software, website, and services ("Services"). By accessing or using our Services, you agree to comply with these terms.
              </p>

              <h5>2. Acceptance of Terms</h5>
              <p>
                By accessing our Services, you agree to be bound by these Terms and Conditions. If you do not agree to all of the terms, then you may not access or use the Services.
              </p>

              <h5>3. Use of Services</h5>
              <p>
                You must follow any policies made available to you within the Services. Do not misuse our Services, and use them only as permitted by law. The Services are for personal and commercial use as expressly authorized by us.
              </p>

              <h5>4. License and Restrictions</h5>
              <p>
                We grant you a limited, non-exclusive, non-transferable, revocable license to use our Services. You are expressly prohibited from:
                <ul>
                  <li>Modifying, reverse engineering, decompiling, disassembling, or creating derivative works based on the software.</li>
                  <li>Bypassing any security measures to access, distribute, or tamper with the software.</li>
                  <li>Using the software for any illegal, harmful, or malicious purposes.</li>
                  <li>Attempting to exploit or gain unauthorized access to our systems or networks.</li>
                </ul>
              </p>

              <h5>5. Intellectual Property</h5>
              <p>
                All content, features, and functionality of the Services (including software, text, graphics, logos, images, and designs) are and will remain the exclusive property of AI Drone Software and Tensor Pilot and their licensors. You are not granted any right or license to use any of our trademarks, service marks, copyrights, or other proprietary material except as specifically authorized by us.
              </p>

              <h5>6. Software Updates and Patches</h5>
              <p>
                Our software may update automatically from time to time to improve, enhance, and develop our Services. These updates may take the form of bug fixes, patches, enhanced functions, new software modules, or completely new versions. You agree to receive such updates as part of your use of the Services.
              </p>

              <h5>7. No Unauthorized Distribution</h5>
              <p>
                You are not permitted to copy, distribute, sell, sublicense, rent, lease, or transfer the software or any part of the Services to any third party. Any unauthorized attempt to do so is a violation of these terms.
              </p>

              <h5>8. User Accounts</h5>
              <p>
                To use certain features of the Services, you may need to create a user account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device. You agree to accept responsibility for all activities that occur under your account.
              </p>

              <h5>9. Privacy and Data Protection</h5>
              <p>
                Your privacy is important to us. Please review our Privacy Policy, which explains how we collect, use, and safeguard your information when you use our Services. By using the Services, you agree to be bound by our Privacy Policy.
              </p>

              <h5>10. Disclaimer of Warranties</h5>
              <p>
                The Services are provided "as is" and "as available." We make no warranties or representations of any kind, whether express, implied, or statutory, regarding the Services, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement.
              </p>

              <h5>11. Limitation of Liability</h5>
              <p>
                To the fullest extent permitted by law, AI Drone Software and Tensor Pilot shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use or inability to use the Services.
              </p>

              <h5>12. Termination and Suspension</h5>
              <p>
                We may suspend or terminate your access to our Services if you do not comply with these terms or if we are investigating suspected misconduct. Upon termination, your rights to use the Services will cease immediately, and you must destroy any copies of the software in your possession.
              </p>

              <h5>13. Indemnification</h5>
              <p>
                You agree to defend, indemnify, and hold harmless AI Drone Software and Tensor Pilot and their affiliates from and against any and all claims, damages, losses, liabilities, and expenses arising from or related to your use of the Services or violation of these terms.
              </p>

              <h5>14. Governing Law and Dispute Resolution</h5>
              <p>
                These terms shall be governed by and construed in accordance with the laws of the jurisdiction in which our company is established, without regard to its conflict of law principles. Any disputes or claims arising from or related to these terms will be subject to the exclusive jurisdiction of the courts in that jurisdiction.
              </p>

              <h5>15. Changes to Terms and Conditions</h5>
              <p>
                We may modify these terms or any additional terms that apply to our Services. You should review the terms regularly. Changes will become effective no sooner than fourteen days after they are posted and will not apply retroactively.
              </p>

              <h5>16. Contact Information</h5>
              <p>
                If you have any questions about these Terms and Conditions, please contact us at support@aidronesoftware.com.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default TermsAndConditions;
