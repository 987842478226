import React, { useState } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './css/FAQ.css';

const FAQ = () => {
    const [activeKey, setActiveKey] = useState(null);
    const [visibleCount, setVisibleCount] = useState(5);

    const toggleFAQ = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };

    const showMoreFAQs = () => {
        setVisibleCount(visibleCount + 6);
    };

    const faqs = [
        {
            key: '0',
            question: 'Which drones are compatible with your software?',
            answer: 'Currently, our software is compatible with the DJI Tello drone. This educational drone is ideal for individual enthusiasts and students to learn the basics of drone operation and coding. We are working on expanding compatibility to include more drones in the future. For custom drone integration, please contact us. We offer enterprise-level solutions and can integrate our software with your custom drones.'
        },
        {
            key: '1',
            question: 'Is the software free to use?',
            answer: 'Yes, our software is free to download and to be used with your Tello drone.'
        },
        {
            key: '2',
            question: 'Can your software be integrated with custom drones and flight controllers?',
            answer: 'Yes, we strive to make our software compatible with a wide range of drones. We also offer bespoke integrations for custom drones. Please contact us for more information on how we can work with your drone\'s API to integrate it with our software.'
        },
        {
            key: '4',
            question: 'How do I get started with Tensor Pilot',
            answer: 'You can download the MacOS application for free from our website and the Windows application from Windows Store.'
        },
        {
            key: '5',
            question: 'What features are included in the Pro subscription?',
            answer: 'The Pro subscription includes advanced AI capabilities such as object detection, automated flight modes, cinematic filming techniques, and enhanced data analytics. It also offers priority support and access to new features as they are released.'
        },
        {
            key: '6',
            question: 'What kind of support do you offer?',
            answer: 'We offer a range of support options including email support, live chat, and a community forum. Pro subscribers receive priority support with faster response times and access to exclusive troubleshooting resources.'
        },
        {
            key: '7',
            question: 'What are the system requirements for the software?',
            answer: 'The software requires a Windows or macOS operating system, a modern multi-core processor, at least 8GB of RAM, and a stable internet connection for updates and some features. Detailed system requirements can be found on our website.'
        },
        {
            key: '8',
            question: 'Is the software compatible with mobile devices?',
            answer: 'Currently, our software is primarily designed for desktop and laptop computers. We are working on a mobile version to provide greater flexibility and convenience for our users.'
        }
    ];

    return (
        <div className="faq-container mt-5">
            <h3 className='text-center mb-4'>
                FAQ
            </h3>
            <Accordion activeKey={activeKey}>
                {faqs.slice(0, visibleCount).map(faq => (
                    <Card key={faq.key} className="faq-item">
                        <Card.Header onClick={() => toggleFAQ(faq.key)} className="faq-header">
                            <Accordion.Toggle as={Button} variant="" className="faq-button">
                                <div className="faq-content">
                                    <h5 className="faq-question">{faq.question}</h5>
                                    <FontAwesomeIcon 
                                        icon={activeKey === faq.key ? faChevronUp : faChevronDown} 
                                        className="faq-icon" 
                                    />
                                </div>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={faq.key}>
                            <Card.Body className="faq-body">{faq.answer}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
            {visibleCount < faqs.length && (
                <div className="text-center mt-4">
                    <Button onClick={showMoreFAQs} variant="primary">Show More</Button>
                </div>
            )}
        </div>
    );
};

export default FAQ;
