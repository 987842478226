// src/pages/NotFound.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Helmet from 'react-helmet';
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import './NotFound.css'

const NotFound = () => {

  return (
    <>
        <Helmet>
            <title>Sign Up - AI Drone Software</title>
            <meta name="description" content="Sign Up to download our software today!" />
            <meta name="keywords" content="Drone AI Software, Drone Software, AI Drone, Sign up" />
            <meta property="og:title" content="Sign Up - AI Drone Software" />
            <meta property="og:description" name="description" className="swiftype" content="Sign Up to download our software today!" />
            <meta property="og:url" content="https://www.aidronesoftware.com/signup" />
            <meta property="og:site_name" content="Sign Up - AI Drone Software" />
        </Helmet>
        <NavBarMain />
        <div className="not-found-page d-flex flex-column">
        <Container className="text-center my-5">
        <Row>
            <Col>
            <h1 className="display-4">404 - Page Not Found</h1>
            <p className="lead">Sorry, the page you are looking for does not exist.</p>
            <Button 
                variant="outline-primary" 
                href="/"
            >
                Go to Homepage
            </Button>
            </Col>
        </Row>
        </Container>
        </div>
    </>
  );
}

export default NotFound;
