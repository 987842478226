import React from 'react';
import { Container, Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard, faGamepad, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './css/DroneControlOptions.css';

const DroneControlOptions = () => {

    const renderTooltip = (text) => (
        <Tooltip id="button-tooltip">
            {text}
        </Tooltip>
    );

    return (
        <div className="control-options-section mt-5">
            <Container>
                <h3 className="text-center mb-1">Dual Controls</h3>
                <p className="text-center mb-4">
                    Use both Keyboard & Gamepad simultaneously for enhanced flexibility and control.
                </p>
                <Row className='justify-content-center'>
                    <Col xs={12} sm={6} md={6} lg={4} className="mb-4">
                        <Card className="feature-card h-100 text-center shadow-sm d-flex flex-column align-items-center justify-content-center">
                            <Card.Body className="d-flex flex-column align-items-center">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip('Keyboard Controls')}
                                >
                                    <FontAwesomeIcon 
                                        icon={faKeyboard} 
                                        size="3x" 
                                        className="feature-icon mb-3" 
                                        // onClick handler removed as modal is no longer needed
                                    />
                                </OverlayTrigger>
                                <Card.Title>Keyboard</Card.Title>
                                <Card.Text>
                                    Precise & Quick Commands
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} className="mb-4">
                        <Card className="feature-card h-100 text-center shadow-sm d-flex flex-column align-items-center justify-content-center">
                            <Card.Body className="d-flex flex-column align-items-center">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip('Gamepad Controls')}
                                >
                                    <FontAwesomeIcon 
                                        icon={faGamepad} 
                                        size="3x" 
                                        className="feature-icon mb-3"
                                        // onClick handler removed as modal is no longer needed
                                    />
                                </OverlayTrigger>
                                <Card.Title>Gamepad</Card.Title>
                                <Card.Text>
                                    Ergonomic & Intuitive<br/>
                                    <small className="text-muted">
                                        * Tested with Xbox 360 and Logitech controllers
                                    </small>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default DroneControlOptions;
