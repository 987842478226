import React, { useRef, useState, useEffect, createRef } from "react";
import { Row,Col, Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Helmet from 'react-helmet';
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import LogoImg from '../../assets/Logo-HighRes.png'; 
import axios from 'axios';
import Logo from '../../assets/Logo.png';

import './Login.css';

export default function Login(props) {
  const emailRef = useRef();
  const passwordRef = useRef();
  const currentPasswordRef = useRef();
  const passwordConfirmRef = useRef();
  const { login, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1); // Step 1: Email input, Step 2: Password input or Password reset
  const [isOldUser, setIsOldUser] = useState(false);
  const [token, setToken] = useState("");
  const reCaptchaRef = createRef();
  const history = useHistory();

  useEffect(() => {
    let isMounted = true; // Add flag to track if component is mounted

    if (currentUser && currentUser.email !== 'undefined') {
      if (isMounted) {
        history.push("/profile");
      }
    }

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false when component unmounts
    };
  }, [currentUser, history]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      const response = await axios.get('/api/ifuserexists', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Email': emailRef.current.value
        }
      });

      if (response.status === 201) {
        // User exists in the new DB, proceed to login
        setStep(2);
        setIsOldUser(false);
      } else if (response.status === 202) {
        // User exists in the old DB and needs migration
        setStep(3);
        setIsOldUser(true);
      } else if (response.status === 200) {
        // User does not exist
        setStep(1);
        setError("No user found with this email. Please sign up to create an account.");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // User does not exist
        setStep(4);
      } else {
        setError("Failed to check email");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    
    if (!token) {
      return setError("You must verify the captcha");
    }

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      history.push("/profile");
    } catch (error) {
      setError("Failed to log in");
    } finally {
      setLoading(false);
    }
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      const response = await axios.post('/api/migrateuser', {
        email: emailRef.current.value,
        currentPassword: currentPasswordRef.current.value,
        newPassword: passwordRef.current.value
      });
      if (response.status === 201) {
        // User migrated successfully, now log them in
        await login(emailRef.current.value, passwordRef.current.value);
        history.push("/profile");
      }
    } catch (error) {
      setError("Failed to reset password. Please contact us if you need any assistance with login.");
    } finally {
      setLoading(false);
    }
  };

  const { location } = props;

  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>Login - Tensor Pilot</title>

        {/* Meta Description for SEO */}
        <meta name="description" content="Login to access Tensor Pilot AI Drone Software, download the latest updates, manage your subscriptions, and explore advanced drone capabilities." />

        {/* SEO Keywords */}
        <meta name="keywords" content="Login, Sign In, Access Account, User Login, Member Login, Account Access, Secure Login, Tensor Pilot AI Drone Software Login, Drone AI Software Login, Drone Software Access, AI Drone Sign In, Account Sign In, User Authentication, Secure Access, Member Sign In, Tensor Pilot Features, Human Pose Detection Login, Object Detection Login, Face Mesh AI Login, Hand Gesture Recognition Login, Drone Software Updates, Remote Control Login, Flying with Keyboard Login, Drone Cinematography Login, Tello Drone Compatibility, AI-Powered Drones, Drone Software Management" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Login - Tensor Pilot AI Drone Software" />
        <meta property="og:description" content="Login to access Tensor Pilot AI Drone Software, manage your account, and explore advanced features like AI-powered object detection, human pose detection, and more." />
        <meta property="og:url" content="https://www.aidronesoftware.com/login" />
        <meta property="og:site_name" content="Tensor Pilot - AI Drone Software" />
        <meta property="og:image" content={Logo} /> 
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Login - Tensor Pilot an AI Drone Software" />
        <meta name="twitter:description" content="Sign in to Tensor Pilot AI Drone Software to manage your account and access advanced AI features for drones." />
        <meta name="twitter:image" content={Logo} /> 

        {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <NavBarMain />
      <div className="full-bg">
        <Row className="justify-content-md-center">
          <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={2}>
              <div className="hero">
                <center>
                  <h4 className="hero__headline">Tensor Pilot</h4>
                </center>
                <p className="slogan mt-2">Smart Flight Software for Drones.</p>
                <center>
                  <img 
                    src={LogoImg} 
                    className="login-logo" 
                    alt="Logo" 
                  />
                </center>
                <Card style={{ backgroundColor: "rgba(229, 229, 247, 0.5)", border: "none" }}>
                  <Card.Body style={{ backgroundColor: "rgba(229, 229, 247, 0.5)", border: "none" }}>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={step === 1 ? handleEmailSubmit : isOldUser ? handleResetPasswordSubmit : handleLoginSubmit}>
                      <Form.Group id="email">
                        <Form.Label><b>Email</b></Form.Label>
                        <Form.Control type="email" ref={emailRef} required disabled={step !== 1} />
                      </Form.Group>
                      {step === 2 && !isOldUser && (
                        <>
                          <Form.Group id="password">
                            <Form.Label><b>Password</b></Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                          </Form.Group>
                          <Form.Group>
                            <center>
                            <ReCAPTCHA
                              ref={reCaptchaRef}
                              sitekey="6LcjnS4bAAAAAI3HKCTce9dAnOucKEalagR65dtr"
                              onChange={token => setToken(token)}
                              onExpired={() => { reCaptchaRef.current.reset() }}
                            />
                            </center>
                          </Form.Group>
                        </>
                      )}
                      {step === 3 && isOldUser && (
                        <>
                          <Form.Group id="current-password">
                            <Form.Label><b>Current Password</b></Form.Label>
                            <Form.Control type="password" ref={currentPasswordRef} required />
                          </Form.Group>
                          <Form.Group id="password">
                            <Form.Label><b>New Password</b></Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                          </Form.Group>
                          <Form.Group id="password-confirm">
                            <Form.Label><b>Confirm New Password</b></Form.Label>
                            <Form.Control type="password" ref={passwordConfirmRef} required />
                          </Form.Group>
                        </>
                      )}
                      <Button disabled={loading} className="w-100 mt-3 hero__button" type="submit">
                        <b>{loading ? (step === 1 ? "Checking..." : "Submitting...") : (step === 1 ? "Next" : "Submit")}</b>
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
                <div className="w-100 text-center mt-4">
                  Need an account? <Link to={{ pathname: '/signup', state: location.state }}>Sign Up</Link>
                </div>
                <div className="w-100 text-center mt-3">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
              </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
