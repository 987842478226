import axios from 'axios';

export async function sessionLogin(idToken) {
  try {
    const response = await axios.post('/api/sessionLogin', { idToken }, {
      withCredentials: true
    });
    console.log(response);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Request made and server responded with a status code outside of the 2xx range
      console.error('Error response:', error.response);
      throw new Error(error.response.data.error || 'Server error');
    } else if (error.request) {
      // Request made but no response received
      console.error('Error request:', error.request);
      throw new Error('No response from server');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message:', error.message);
      throw new Error('Error setting up request');
    }
  }
}