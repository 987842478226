import React from 'react';
import { Card, Row, Col} from "react-bootstrap";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt, faEnvelope, faCalendar, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faPencilAlt, faEnvelope, faCalendar, faUser);

const UserInformation = (props) => {
	
	return (
        <Card className="shadow-sm border-0">
            <Card.Header className="bg-primary text-white d-flex justify-content-start">
                <h5 className="mb-0">Profile</h5>
            </Card.Header>
            <Card.Body>
			<Row className="mb-1">
				<Col md={6} className="d-flex justify-content-start">
					<FontAwesomeIcon icon={faUser} className="text-primary mr-2" />
					<p className="mb-0">{props.Firstname} {props.Lastname}</p>
				</Col>
				<Col md={6} className="d-flex justify-content-start">
					<FontAwesomeIcon icon={faEnvelope} className="text-primary mr-2" />
					<p className="mb-0">{props.useremail}</p>
				</Col>
				<Col md={6} className="d-flex justify-content-start">
					<FontAwesomeIcon icon={faCalendar} className="text-primary mr-2" />
					<p className="mb-0">{props.Dob}</p>
				</Col>
			</Row>
            </Card.Body>
        </Card>
    );
}

export default UserInformation;
