import React, { useContext, useState, useEffect } from "react";
import { auth, database } from "../firebase";
import { Alert } from "react-bootstrap";
import { sessionLogin } from './BackendAPI'; // Import the sessionLogin function

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [logoutMessage, setLogoutMessage] = useState(""); // State for logout message
  const [isSystemLogout, setIsSystemLogout] = useState(false); // State to track if logout is system-initiated

  async function signup(email, password, firstname, lastname, dob, flyingexperience, numberofdrones, accountType, token) {
    try {
      const cred = await auth.createUserWithEmailAndPassword(email, password);
      await database.users.doc(cred.user.uid).set({
        firstname: firstname,
        lastname: lastname,
        dob: dob,
        flyingexperience: flyingexperience,
        numberofdrones: numberofdrones,
        accountType: accountType,
        subscriptiontype: "Basic", // All users should be able to download the free/basic software version
        subscriptionKey: cred.user.uid,
        reCAPTCHAToken: token // Store the reCAPTCHA token
      });
      const idToken = await cred.user.getIdToken(true);
      await sessionLogin(idToken);
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('Email already in use.');
      } else if (error.code === 'auth/weak-password') {
        throw new Error('Password is too weak.');
      } else {
        throw new Error('Failed to create an account.');
      }
    }
  }

  async function login(email, password) {
    try {
      await auth.signInWithEmailAndPassword(email, password);
      const idToken = await auth.currentUser.getIdToken(true);
      const sessionResponse = await sessionLogin(idToken);
  
      if (sessionResponse.status === 200) {  // Ensure session login is successful
        localStorage.setItem('loginTimestamp', Date.now());
        return true;  // Indicate success
      } else {
        throw new Error('Failed to create session.');
      }
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        throw new Error('No user found with this email.');
      } else if (error.code === 'auth/wrong-password') {
        throw new Error('Incorrect password.');
      } else {
        throw new Error('Failed to log in.');
      }
    }
  }
  

  function logout(isSystem = false) {
    if (isSystem) {
      console.log("Auto Logout");
      setLogoutMessage("You have been logged out due to inactivity."); // Set logout message for system logout
    } else {
      console.log("Logout triggered by user");
      setLogoutMessage(""); // Clear logout message for user-initiated logout
    }
    return auth.signOut().then(() => {
      localStorage.removeItem('loginTimestamp');
    });
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const loginTimestamp = localStorage.getItem('loginTimestamp');
        const oneWeek = 7 * 24 * 60 * 60 * 1000; // one week in milliseconds

        if (loginTimestamp && (Date.now() - loginTimestamp) > oneWeek) {
          setIsSystemLogout(true);
          logout(true).then(() => {
            setCurrentUser(null);
          });
        } else {
          setCurrentUser(user);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    }, error => {
      console.error('Auth state change error:', error);
    });

    return unsubscribe;
  }, []);

  // Implement the periodic token refresh inside useEffect as long as the tab is open, the backend api cookie will be refreshes
  useEffect(() => {
    const intervalId = setInterval(() => {
      auth.currentUser?.getIdToken(true).then(idToken => {
        sessionLogin(idToken); // Call the sessionLogin function to refresh the session on the server
      });
    }, 30 * 60 * 1000); // Refresh every 30 minutes
    
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  useEffect(() => {
    let timer;
    if (logoutMessage) {
      timer = setTimeout(() => {
        setLogoutMessage("");
        setIsSystemLogout(false);
      }, 5000); // Hide alert after 5 seconds
    }
    return () => clearTimeout(timer); // Cleanup the timeout on component unmount or when logoutMessage changes
  }, [logoutMessage]);

  const value = {
    currentUser,
    login,
    signup,
    logout: () => logout(false), // Pass false for user-initiated logout
    resetPassword,
    updatePassword
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && (
        <>
          {isSystemLogout && logoutMessage && <Alert variant="info">{logoutMessage}</Alert>} {/* Display logout message */}
          {children}
        </>
      )}
    </AuthContext.Provider>
  );
}
