import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {faUniversalAccess, 
    faLightbulb, 
    faSmileBeam, 
    faHandSparkles, 
    faVideo,
    faGamepad,
    faGavel,
    faKeyboard,
    faRocket,
    faCog } from '@fortawesome/free-solid-svg-icons';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';

import FreeSubscriptionCard from './SubscriptionCardInfo';

const StudentsEducators = () => {
    const schoolFeatures = [
        { text: "AI - Object Detection", available: true, iconName: faUniversalAccess},
        { text: "AI - Human Pose Detection", available: true, iconName:faLightbulb },
        { text: "AI - Face Mesh", available: true, iconName: faSmileBeam },
        { text: "AI - Hand Gesture", available: true, iconName: faHandSparkles },
        { text: "Cinematography Modes", available: true, iconName: faVideo },
        { text: "Remote Control", available: true, iconName:faGamepad },
        { text: "Keyboard Control", available: true, iconName:faKeyboard },
        { text: "Non Commercial License", available: false, iconName:faGavel  },
        { text: "Compatible with Apple", available: true, iconName:faApple  },
        { text: "Compatible with Windows", available: true, iconName:faWindows  },
        { text: "Software Upgrades", available: true, iconName:faCog  },
        { text: "Tello Drone Compatible", available: true, iconName: faRocket },
    ];
    

  return (
    <div id="schooling" className="mt-2">
      <Row className="justify-content-center">
        <Col md={7} lg={7} sm={12} className="d-flex align-items-center">
          <div className='text-justify'>
            <h4>Bulk Licensing for Schools</h4>
            <p>
              Our software is ideal for educational institutions looking to integrate drone technology into their curriculum. We offer bulk licensing options that provide access to all the advanced features of our software for multiple users within a school.
            </p>
            <p>
              This enables teachers to provide hands-on learning experiences with drones, covering topics such as AI, robotics, and computer vision. Our software supports collaborative learning, with features designed to facilitate group projects and classroom activities.
            </p>
            <p>
              <a href="/contactus">Contact us</a> today to discuss bulk licensing options and how we can support your educational goals with our cutting-edge drone software.
            </p>
          </div>
        </Col>

        <Col md={5} lg={5} sm={12} className="mt-2">
          <FreeSubscriptionCard
              title="Tensor Pilot AI Edu"
              description="Transform your classrooms into Flight Labs"
              features={schoolFeatures}
              onButtonClick={() => window.location.href = '/contactus'}
              buttonText="Get In Touch"
            />
        </Col>
      </Row>
    </div>
  );
};

export default StudentsEducators;
