import 'react-bootstrap-drawer/lib/style.css';
import React, { useState } from 'react';
import { Col, Collapse, Container, Row, Card} from 'react-bootstrap';
import Scrollspy from 'react-scrollspy'
import NavBarMain from '../../sharedcomponents/navbar/Navbar'
import { Link } from "react-router-dom"

import DroneControl from './DroneControl.png';
import DroneSpeed from './DroneSpeed.png'
import FlightPlanner from './FlightPlanner.png'
import XboxController from './XboxController.png'
import LogitechF310 from './LogitechF310.png'
import Helmet from 'react-helmet';
import Youtubeplayer from '../../sharedcomponents/youtubeplayer/youtubeplayer'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHeadset, faUniversalAccess, faLightbulb, faRobot, 
  faSurprise, faExclamationCircle, faFighterJet, faRoute,faVideo, 
  faCamera, faStar, faClock, faBullseye, faArrowsAlt, faGamepad, faKeyboard, faMouse} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(faHeadset, faUniversalAccess, faLightbulb, 
  faRobot, faSurprise,faExclamationCircle, faFighterJet, 
  faRoute,faVideo, faCamera, faStar, faClock, faBullseye, faArrowsAlt, faGamepad, faKeyboard, faMouse)


const ApplicationDrawer = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen(!open);

  return (
    <Card className="border-0 pl-5 pr-5">
      <Card.Body className="overflow-auto">
        <Scrollspy 
          items={ ['Drone-Software', 'Compatibility', 'Installation','Software-Features','Drone-Control','Set-Drone-Speed','Pre-Planned-Drone-Flight',
          'AI-Object-Detection','AI-Human-Pose-Detection','AI-Voice-Command','AI-Face-Tracking','AI-Collision-Avoidance','AI-Collision-Avoidance',
          'AI-Swarm-Control','AI-Trajectory-Prediction','Cinematography-Record-Video-Feed','Cinematography-Capture-image',
          'Cinematography-Burst-Mode','Cinematography-Burst-Mode','Cinematography-Panoroma','Cinematography-Timer','Cinematography-Multiple-Camera-Feed'] } 
          currentClassName="is-current"
        >
          <center><p className="text-muted">Table Content</p></center>
          <ul>
            <li><a href="#Drone-Software">Drone Software</a></li>
            <ul>
              <li><a href="#Compatibility">Compatibility</a></li>
              <li><a href="#Installation">Installation</a></li>
            </ul>
            <li><a href="#Software-Features">Software Features</a></li>
            <ul>
              <li><a href="#Drone-Control">Drone Control</a></li>
              <li><a href="#Set-Drone-Speed">Set Drone Speed</a></li>
              <li><a href="#Pre-Planned-Drone-Flight">Pre Planned Drone Flight</a></li>
              <li><a href="#AI-Object-Detection">AI - Object Detection</a></li>
              <li><a href="#AI-Human-Pose-Detection">AI - Human Pose Detection</a></li>
              <li><a href="#AI-Voice-Command">AI - Voice Command</a></li>
              <li><a href="#AI-Face-Tracking">AI - Face Tracking</a></li>
              <li><a href="#AI-Collision-Avoidance">AI - Collision Avoidance</a></li>
              <li><a href="#AI-Swarm-Control">AI - Swarm Control</a></li>
              <li><a href="#AI-Trajectory-Prediction">AI - Trajectory Prediction</a></li>
              <li><a href="#Cinematography-Record-Video-Feed">Cinematography - Record Video Feed</a></li>
              <li><a href="#Cinematography-Capture-image">Cinematography - Capture image</a></li>
              <li><a href="#Cinematography-Burst-Mode">Cinematography - Burst Mode</a></li>
              <li><a href="#Cinematography-Panoroma">Cinematography - Panoroma</a></li>
              <li><a href="#Cinematography-Timer">Cinematography - Timer</a></li>
              <li><a href="#Cinematography-Multiple-Camera-Feed">Cinematography - Multiple Camera Feed</a></li>
            </ul>
          </ul>
        </Scrollspy>
      </Card.Body>
    </Card>
  );
};

const SoftwareGuide = (props) => {
  return (
    <>
      <Helmet>
        <title>Software Guide - AI Drone Software </title>
        <meta name="description" content="Software Guide and tutorial on how to download, install and use the software. Also learn about the AI features bundled in the software." />
        <meta name="keywords" content="Drone AI Software, Drone Software, AI Drone, Software Guide, Documentation, Tutorial"/>
        <meta property="og:title" content="Software Guide - AI Drone Software" />
        <meta property="og:description" name="description" className="swiftype" content="Software Guide and tutorial on how to download, install and use the software. Also learn about the AI features bundled in the software."/>
        <meta property="og:url" content="https://www.aidronesoftware.com/contactus"/>
        <meta property="og:site_name" content="Software Guide - AI Drone Software"/>
      </Helmet>
      <NavBarMain />
      <Container fluid>
        <Row className="flex-xl-nowrap">
          <Col className="pt-2" as={ ApplicationDrawer } xs={ 12 } sm={3} md={ 3 } lg={ 3 } />
          
          <Col className="pt-4" xs={ 12 } sm={9} md={ 9 } lg={ 9 }>
          <div className="pl-5 pr-5">

            <section id="Drone-Software">
                <h5>Drone Software</h5>
                <p>Eli Drone Software allows you to fly your drone from computer. Traditional iOS & android applications offers only basic drone control. 
                Our software not only offer advance drone controlling techniques but additional AI features. A combination of bigger display screen, better controls &
                AI power gives a better user experience. Our software makes your drone smarter for a little extra cost, drones with those capabilities costs 50 times 
                more. We shift all the computation from your drone to the computer.</p>
              <p><Link to="/application">Drone Software</Link> have tons of features to offer -</p>
              <ul>
                <li>Drone Control</li>
                <li>Set Drone Speed</li>
                <li>Pre-Planned Drone Flight</li>
                <li>Visualize Drone VPS</li>
                <li>Detailed Flight/Drone Data</li>
                <li>AI - Object Detection</li>
                <li>AI - Human Pose Detection</li>
                <li>AI - Voice Command</li>
                <li>AI - Face Tracking</li>
                <li className="text-muted">AI - Collision Avoidance (In Development Pipeline)</li>
                <li className="text-muted">AI - Swarm Control (In Development Pipeline)</li>
                <li className="text-muted">AI - Trajectory Prediction (In Development Pipeline)</li>
                <li>Cinematography - Record Video Feed</li>
                <li>Cinematography - Capture image</li>
                <li>Cinematography - Burst Mode</li>
                <li>Cinematography - Panoroma</li>
                <li>Cinematography - Timer</li>
                <li className="text-muted">Cinematography - Multiple Camera Feed(In Development Pipeline)</li>
              </ul>
            </section>


            <section id="Compatibility">
                <h5 className="pt-1 pb-1 pl-1" style={{"backgroundColor":"lightgrey"}}>Compatiblity</h5>
                <p>Software Compatiblity with Drones</p>
                <ul>
                  <li><a target="_blank" href="https://www.ryzerobotics.com/tello" rel="noopener">Tello Drones</a></li>
                  <li>We are in process of adding new popular drones for software. Individuals can request a drone <Link to="/contactus">getting in touch with us</Link>.</li> 
                  <li>For enterprise solution please reach us out on <a href="#">support@aidronesoftware.com</a></li>
                </ul> 
                <p>Software can run on:</p>
                <ul>
                  <li>MacOS Big Sur</li>
                  <li>Windows 10</li> 
                </ul>
            </section>
            

            <section id="Installation">
              <div className="pt-2">
                <h5 className="pt-1 pb-1 pl-1" style={{"backgroundColor":"lightgrey"}}>Installation</h5>
                <ul>
                  <li>Mac Installation</li>
                  <Row>
                    <Col lg={6} sm={12} md={8}>
                      <Youtubeplayer url="https://www.youtube.com/watch?v=8PVUrKrLbv4" width="100%" height="100%"/>
                    </Col>
                  </Row>

                  <li className="mt-3">Windows Installation</li>
                  <Row>
                    <Col  lg={6} sm={12} md={8}>
                      <Youtubeplayer url="https://www.youtube.com/watch?v=Afysk-lqWGc&t=5s" width="100%" height="100%" />
                    </Col>
                  </Row>
                </ul>

              </div>
            </section>


            <section id="Software-Features">
                <h5  className="pt-1 pb-1 pl-1" style={{"backgroundColor":"lightgrey"}}>Software Features</h5>
            </section>

            <section id="Drone-Control">
              <h6><b>Drone Control</b></h6>
              <p>The drone can be controlled using 3 differnet ways</p>
              <ul style={{"listStyle":"none"}}>
                
                
                <li><h6 className="mt-3"><b><FontAwesomeIcon icon="mouse" size="2x"/> UI</b></h6></li>
                <img src={DroneControl} width="25%" alt="Drone Control using the Application UI"/>

                <li><h6 className="mt-3"><b><FontAwesomeIcon icon="keyboard" size="2x"/> Keyboard</b></h6></li>
                  <ul>
                    <li>E Key: Take Off</li>
                    <li>Q Key: Land</li>
                    <li>Up Arrow Key: Move Forward</li>
                    <li>Down Arrow Key: Move Back</li>
                    <li>Left Arrow Key: Move Left</li>
                    <li>Right Arrow Key: Move Right</li>
                    <li>W Key: Move Up</li>
                    <li>S Key: Move Down</li>
                    <li>A Key: Rotate Left</li>
                    <li>R Key: Rotate Right</li>
                    <li>AI & Cinematography features can be activated from UI while using Keyboard</li>
                    <li>Speed can be changed in UI</li>
                  </ul>

                <li><h6 className="mt-3"><b><FontAwesomeIcon icon="gamepad" size="2x"/> Gamepad</b></h6></li>
                <p>The software provides option to control the drone using remote control(gamepad). The compatible remote controllers that work with software</p>
                <ul>
                  <li>Xbox 360</li>
                  <img src={XboxController} width="50%" alt="Drone controls using XBox Controller"/>
                  <li>Logitech F310</li>
                  <img src={LogitechF310} width="50%" alt="Drone controls using Logitech Controller"/>
                  <li>Ps4 Controllers</li>
                </ul>

                <li><h6 className="mt-3"><b><FontAwesomeIcon icon="headset" size="2x"/> Speech Control</b></h6></li>
                <p>You can also control drone using your voice. Please read <a href="#AI-Voice-Command">Voice Command</a></p>
              </ul>
            </section>

            <section id="Set-Drone-Speed">
              <h6><b>Set Drone Speed</b></h6>
              <p>You can adjust the drone speed to desired speed between to 0.1 to X m/sec. The flight speed will remain set throughout the flight.</p>
              <p className="text-danger"> WARNING : High speeds are very risky & lead to drone crashes. Please operate with caution.</p>
              <img src={DroneSpeed} width="25%" alt="Drone Speed control slider Application UI"/>
            </section>

            <section id="Pre-Planned-Drone-Flight" className="mt-3">
              <h6><b>Pre Planned Drone Flight</b></h6>
              <p>Users can plan their flight by setting in directions, speed & distance. There are 3 modes which are already designed for you "Straight","Square" & "Rectangle".
              Choose "Custom" to design your own plan. Add & Delete the steps as you like.</p>
              <img src={FlightPlanner} width="60%" alt="Flight Planner - Plan a flight scheme"/>
            </section>

            <section id="AI-Features" className="mt-3">
                <h5 className="pt-1 pb-1 pl-1" style={{"backgroundColor":"lightgrey"}}>AI Features</h5>
                <p>You can activate AI features of drone while flying the drone. The drone can be AI features can be activated from the UI by clicking on buttons.</p>
            </section>

            <section id="AI-Object-Detection">
              <h6>
                <FontAwesomeIcon 
                    className="btn" 
                    icon="lightbulb" 
                    style={circleIconOn} 
                  />
                  <b> Object Detection</b>
              </h6>
              <p>
                During flight the drone can recognize objects in it's view. To activate object detection click on the bulb icon
              </p>
            </section>

            <section id="AI-Human-Pose-Detection">
            <h6>
                <FontAwesomeIcon 
                    className="btn" 
                    icon="universal-access" 
                    style={circleIconOn} 
                  />
                  <b> Human Pose Detection</b>
              </h6>
              <p>Drone Software have the ability to detect human poses. It can recognize moving human beings & map out their behaviors & activity.</p>
              <p className="text-muted">In next phase of application you will be able to give commands from hand/body signals to the drone.</p>
            </section>

            <section id="AI-Voice-Command">
              <h6>
                <FontAwesomeIcon 
                    className="btn" 
                    icon="headset" 
                    style={circleIconOn} 
                  />
                  <b> Voice Command </b>
              </h6>
              <p>Pilots can also control drone with voice commands. You will need your earphones/headphones to capture clear voice commands. If there is too much 
              background noise or voice is not clear, the commands won't work. Drone can be controlled by saying below commands into earphone- </p>
              <ul>
                <li><i>move left</i>: drone travels left</li>
                <li><i>move right</i>: drone travels right</li>
                <li><i>move back</i>: drone travels backward</li>
                <li><i>move front</i>: drone travels forward</li>
                <li><i>move up</i>: drone travels up</li>
                <li><i>move down</i>: drone travels down</li>
                <li><i>rotate clock</i>: drone move clock-wise</li>
                <li><i>rotate anticlock</i>: drone move anti-clock-wise</li>
              </ul>
            </section>

            <section id="AI-Face-Tracking">
            <h6>
                <FontAwesomeIcon 
                    className="btn" 
                    icon="surprise" 
                    style={circleIconOn} 
                  />
                  <b> Face Tracking </b>
              </h6>
              <p>First Stage: The drone will follow the nearest face in it's view.</p>
              <p>Development Pipeline: The drone will follow any object which user selects</p>
            </section>

            <section id="AI-Collision-Avoidance">
            <h6 className="text-muted">
                <FontAwesomeIcon 
                    className="btn" 
                    icon="exclamation-circle" 
                    style={circleIconOn} 
                  />
                  <b> Collision Avoidance (Develpoment Pipeline)</b>
              </h6>
              <p>In flight mode if 'Collision Avoidance' is turnd on, 
              the drone will auto-detect about to be collisions & take control of drone to get it to 
              safety.</p>
              <p className="text-warning">INFO: Your drone needs to have anti collision sensors or cameras facing in 360 degrees.</p>
            </section>

            <section id="AI-Swarm-Control">
            <h6 className="text-muted">
                <FontAwesomeIcon 
                    className="btn" 
                    icon="fighter-jet" 
                    style={circleIconOn} 
                  />
                  <b> Swarm Control (Develpoment Pipeline)</b>
              </h6>
              <p>The application will soon allow users to control multiple drones with application. One drone act as
              a leader & rest of the drones follow it's direction. They all work in sync & communicate
              with each other</p>
            </section>

            <section id="AI-Trajectory-Prediction">
              <h6 className="text-muted">
              <FontAwesomeIcon 
                    className="btn" 
                    icon="route" 
                    style={circleIconOn} 
                  />
                  <b> Trajectory Prediction (Develpoment Pipeline)</b>
              </h6>
              <p>The software predicts the trajectory for next x seconds based on the speed & direction of travel.
              You will see a yellow dotted line in direction your drone is travelling.</p>
            </section>

            <section id="Cinematography-Features" className="mt-3">
                <h5 className="pt-1 pb-1 pl-1" style={{"backgroundColor":"lightgrey"}}>
                  Cinematography
                </h5>
                <p>Using drones for Cinematography is the most common usecase. By adding pre-designed camera modes we have tried to 
                reduce the stress a pilot faces while multitasking flight & camera control. All the Cinematography
                content is stored in <i>Pictures</i> folder for Mac users. </p>
            </section>

            <section id="Cinematography-Record-Video-Feed">
            <h6>
              <FontAwesomeIcon 
                    className="btn" 
                    icon="video" 
                    style={circleIconOn} 
                  />
                  <b> Record Video Feed</b>
              </h6>
              <p>Click on the button to start recordings. The recording will continue until it's
              switched off. The videos are stored in <i>Pictures/Vid_Recordings</i></p>
            </section>

            <section id="Cinematography-Capture-image">
              <h6>
                <FontAwesomeIcon 
                    className="btn" 
                    icon="camera" 
                    style={circleIconOn} 
                  />
                  <b> Capture Image</b>
              </h6>
              <p>Click on the button to click a single picture. The images are stored in <i>SingleImages</i> inside <i>Pictures/Images</i> folder.</p>
            </section>

            <section id="Cinematography-Burst-Mode">
              <h6>
                <FontAwesomeIcon 
                    className="btn" 
                    icon="bullseye" 
                    style={circleIconOn} 
                  />
                  <b> Burst Mode</b>
              </h6>
              <p>Burst mode clicks 5 pictures with every click. The images are stored in <i>BurstImages</i> inside <i>Pictures/Images</i> folder.</p>
            </section>

            <section id="Cinematography-Panoroma">
              <h6>
                <FontAwesomeIcon 
                    className="btn" 
                    icon="arrows-alt" 
                    style={circleIconOn} 
                  />
                  <b> Cinematography Panoroma</b>
              </h6>
              <p>You can take a 360 Panoroma by clicking on the button. The drone shouldn't be 
              disturbed while it's completing full rotation. The images will be
              stored in the <i>Panorama</i> inside <i>Pictures/Images</i> folder.</p>
            </section>

            <section id="Cinematography-Timer">
            <h6>
                <FontAwesomeIcon 
                    className="btn" 
                    icon="clock" 
                    style={circleIconOn} 
                  />
                  <b> Timer</b>
              </h6>
              <p>Click on the button to time a photo. The count down is set at 5 sec. The images will be
              stored in the <i>SelfTimer</i> inside <i>Pictures/Images</i> folder.</p>
            </section>

            <section id="Cinematography-Multiple-Camera-Feed">
              <h6>
                <FontAwesomeIcon 
                    className="btn" 
                    icon="video" 
                    style={circleIconOn} 
                  />

                <FontAwesomeIcon 
                    className="btn" 
                    icon="video" 
                    style={circleIconOn} 
                  />
                  <b> Multiple Camera Feed</b>
              </h6>
              <p>Users can have multiple camera feed on the screen. Users can opt to have all the camera
              feed on their screen or choose cameras.</p>
              <p className="text-warning">INFO: Your drone should have multiple cameras for this feature.</p>
            </section>

          </div>
          </Col>

        </Row>
      </Container>
    </>
  );
};


const LinkColor = {color:"#3b5998"}
const circleIconOn = {
    backgroundColor: "blue",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    lineHeight: "30px",
    padding: "5px",
    color: "white"
}


export default SoftwareGuide;


