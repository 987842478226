import React, { useEffect, useRef, useState, createRef } from "react";
import { Row, Col, Container, Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import ReCAPTCHA from "react-google-recaptcha";
import Helmet from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import Logo from '../../assets/Logo.png';

import './Signup.css';

library.add(faRedo)

const Signup = (props) => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const firstName = useRef();
  const lastName = useRef();
  const dob = useRef();
  const flyingexperience = useRef();
  const numberofdrones = useRef();
  const accountType = useRef();
  const agreeRef = useRef();

  const { signup, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const history = useHistory();

  const [token, setToken] = useState("");
  const reCaptchaRef = createRef();
  
  const { location } = props;

  useEffect(() => {
    if (currentUser && currentUser.email !== 'undefined') {
      history.push("/profile");
    }
  }, [currentUser, history]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateDOB = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age >= 18 && age <= 100;
  };

  const validateName = (name) => {
    const re = /^[A-Za-z]+$/;
    return re.test(name) && name.length >= 2 && name.length <= 50;
  };

  const validateForm = () => {
    if (!validateEmail(emailRef.current.value)) {
      setError("Invalid email format");
      return false;
    }

    if (!validateName(firstName.current.value)) {
      setError("First Name must be 2-50 characters long and contain only letters");
      return false;
    }
  
    if (!validateName(lastName.current.value)) {
      setError("Last Name must be 2-50 characters long and contain only letters");
      return false;
    }
  

    if (!validateDOB(dob.current.value)) {
      setError("Age must be between 18 and 100");
      return false;
    }

    if (!flyingexperience.current.value) {
      setError("Experience with drones is required");
      return false;
    }

    if (!numberofdrones.current.value) {
      setError("Number of drones is required");
      return false;
    }

    if (!accountType.current.value) {
      setError("Account Type is required");
      return false;
    }

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      setError("Passwords do not match");
      return false;
    }

    if (!agreeRef.current.checked) {
      return setError("You must agree to the Terms & Conditions and Privacy Policy");
    }

    if (!token) {
      setError("Please complete the reCAPTCHA");
      return false;
    }

    setError("");
    return true;
  };

  const handleChange = () => {
    setFormValid(validateForm());
  };


  const handleRecaptchaChange = (token) => {
    if (!token) {
      setError("Please complete the reCAPTCHA");
      setFormValid(false);
    } else {
      setError("");
      setToken(token)
      setFormValid(true);
    }
  };

  const handleRecaptchaExpired = () => {
    console.log("handleRecaptchaExpired")
    setToken("")
    reCaptchaRef.current.reset()
    handleChange()
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let allFieldsAreValidated = validateForm()
    if (!allFieldsAreValidated) {
      return
    }

    setError("");
    setLoading(true);
    try {
      await signup(
        emailRef.current.value,
        passwordRef.current.value,
        firstName.current.value,
        lastName.current.value,
        dob.current.value,
        flyingexperience.current.value,
        numberofdrones.current.value,
        accountType.current.value,
        token
      );
      if (location.state && location.state.from) {
        history.push(location.state.from);
      } else {
        history.push('/profile');
      }
    } catch (err) {
      setError("Failed to create an account " + err.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>Sign Up - Tensor Pilot</title>

        {/* Meta Description for SEO */}
        <meta name="description" content="Sign up to access Tensor Pilot AI Drone Software. Join our community and download the free software today to unlock advanced drone capabilities." />

        {/* SEO Keywords */}
        <meta name="keywords" content="Tensor Pilot, AI Drone Software, Drone Software Sign Up, Download AI Drone Software, Drone Technology, AI Drone Registration, Tensor Pilot Registration" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Sign Up - Tensor Pilot AI Drone Software" />
        <meta property="og:description" content="Join Tensor Pilot today to download our Mac & Windows application. Get started with advanced AI features for drones by signing up now, download the applications for free." />
        <meta property="og:url" content="https://www.aidronesoftware.com/signup" />
        <meta property="og:site_name" content="Tensor Pilot - AI Drone Software" />
        <meta property="og:image" content={Logo} /> 
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sign Up - Tensor Pilot an AI Drone Software" />
        <meta name="twitter:description" content="Sign up for Tensor Pilot AI Drone Software and start using our advanced AI tools for drones today." />
        <meta name="twitter:image" content={Logo} />

        {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <NavBarMain />
      
      <div className="signup-bg">
        <Row className="justify-content-md-center">
          <Col xs={12} sm={12} md={6} lg={6} xl={4} xxl={2}>
            <div className="signup-card">
              <center>
                <h4 className="hero__headline">Sign Up</h4>
              </center>
              <p className="slogan mt-3 mb-4">Download free software from profile</p>
              {error && 
                  <Alert 
                    className="fixed-width-alert"
                    variant="danger">{error}
                  </Alert>
              }

              <Form 
                className="form-css"
                onSubmit={handleSubmit} 
                onChange={handleChange}
              >

                <Form.Group id="email">
                  <Form.Label><b>Email</b></Form.Label>
                  <Form.Control 
                    type="email" ref={emailRef} required placeholder="example@gmail.com" 
                  />
                </Form.Group>

                <Row>
                  <Col md={6}>
                    <Form.Group id="firstname">
                      <Form.Label><b>First Name</b></Form.Label>
                      <Form.Control ref={firstName} required placeholder="First Name" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group id="lastname">
                      <Form.Label><b>Last Name</b></Form.Label>
                      <Form.Control ref={lastName} required placeholder="Last Name" />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group id="dateOfBirth">
                  <Form.Label><b>DOB</b></Form.Label>
                  <Form.Control ref={dob} required type="date" />
                </Form.Group>

                <Row>
                  <Col md={6}>
                    <Form.Group id="flyingexperience">
                      <Form.Label><b>No. of years with drones</b></Form.Label>
                      <Form.Control as="select" ref={flyingexperience} required>
                        {[...Array(50).keys()].map((year) => (
                          <option key={year} value={year}>{year}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group id="numberofdrones">
                      <Form.Label><b>No. of Drones you have</b></Form.Label>
                      <Form.Control as="select" ref={numberofdrones} required>
                        {[...Array(100).keys()].map((year) => (
                          <option key={year} value={year}>{year}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group id="accountType">
                  <Form.Label><b>Account Type</b></Form.Label>
                  <Form.Control as="select" ref={accountType} required>
                    <option value="">Select Account Type</option>
                    <option value="Individual">Individual</option>
                    <option value="School">School</option>
                    <option value="Enterprise">Enterprise</option>
                    <option value="Other">Other</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group id="password">
                  <Form.Label><b>Password</b></Form.Label>
                  <Form.Control type="password" ref={passwordRef} required />
                </Form.Group>

                <Form.Group id="password-confirm">
                  <Form.Label><b>Password Confirmation</b></Form.Label>
                  <Form.Control type="password" ref={passwordConfirmRef} required />
                </Form.Group>


                <Form.Group>
                  <Form.Check 
                    ref={agreeRef}
                    type="checkbox"
                    label={
                      <>
                        I agree to the{' '}
                        <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                          Terms & Conditions
                        </a>{' '}
                        and{' '}
                        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                          Privacy Policy
                        </a>
                      </>
                    }
                    required
                  />
                </Form.Group>

                <div className="d-flex justify-content-center">
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey="6LcjnS4bAAAAAI3HKCTce9dAnOucKEalagR65dtr"
                    onChange={handleRecaptchaChange}
                    onExpired={handleRecaptchaExpired}
                  />
            
                  <Button 
                    onClick={handleRecaptchaExpired}
                    className="btn btn-primary ml-2"
                    disabled={!token}
                  >
                    <FontAwesomeIcon 
                      icon="redo" 
                    /> 
                  </Button>
                </div>
                
                <Button 
                  disabled={!formValid || loading} 
                  className="w-100 btn-custom mt-4" type="submit">
                  Sign Up
                </Button>
              </Form>

              <div className="w-100 text-center mt-3">
                Already have an account? <Link to="/login" className="btn-link">Log In</Link>
              </div>
              
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Signup;
