import React, { useRef, useState, useEffect } from "react"
import { Container, Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserCircle, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavBarMain from '../../sharedcomponents/navbar/Navbar'
import Logo from '../../assets/Logo.png';

import './css/Profile.css'

import UserInformation from './UserInformation'
import UserDroneInformation from './UserDroneInformation'
import UserPassword from './UserPassword'
import Subscription from'./Subscription'
import UpdateUserDroneInformation from './UpdateUserDroneInformation'
import UpdateUserPassword from './UpdateUserPassword'
import Helmet from 'react-helmet';


import {auth, database} from '../../firebase'

library.add(faUserCircle,faEnvelope)

export default function Profile() {
  
  const { currentUser } = useAuth()
  
  const [UpdateUserDroneData, setUpdateUserDroneData] = useState(false)
  const [ChangePassword, setChangePassword] = useState(false)

  const [Firstname, setFirstname] = useState("")
  const [Lastname, setLastname] = useState("")
  const [Dob, setDob] = useState("")
  const [FlyingExperience, setFlyingExperience] = useState("")
  const [NumberOfDrones, setNumberOfDrones] = useState("")
  const [UserDroneList, setUserDroneList] = useState([])
  const [userSubscription, setUserSubscription] = useState(null); // State to track user's subscription

  useEffect(() =>{
    database.users.doc(currentUser.uid).get().then(doc=>{
      setFlyingExperience(doc.data().flyingexperience)
      setNumberOfDrones(doc.data().numberofdronesyouhave)
      setFirstname(doc.data().firstname)
      setLastname(doc.data().lastname)
      setDob(doc.data().dob)
      {doc.data().userdronelist!==undefined?setUserDroneList(doc.data().userdronelist):setUserDroneList([])}
    })  

    // Check if user has paid for Pro Version Also
    database.customers.doc(currentUser.uid)
      .collection('subscriptions')
      .where('status', 'in', [ 'active','past_due','canceled','incomplete','incomplete_expired','trialing','unpaid'])
      .onSnapshot(async (snapshot) => {
        if (!snapshot.empty) {
          const subscriptionInfo = snapshot.docs[0].data()
          setUserSubscription(subscriptionInfo);
        }
    });

  },[UpdateUserDroneData])
  
  return (
      <>
        <Helmet>
          {/* Page Title */}
          <title>Profile - Tensor Pilot</title>

          {/* Meta Description for SEO */}
          <meta name="description" content="Manage your Tensor Pilot profile, update personal details, manage subscriptions, and download the latest AI Drone Software." />

          {/* SEO Keywords */}
          <meta name="keywords" content="Tensor Pilot, AI Drone Software, Drone Profile Management, Manage Profile, Subscription Management, Download Drone Software, Update Personal Details, AI Drone Subscriptions, Drone Software Access, User Profile, Download Tensor Pilot Mac, Tensor Pilot Windows" />

          {/* Open Graph Meta Tags for Social Media */}
          <meta property="og:title" content="Profile - Tensor Pilot AI Drone Software" />
          <meta property="og:description" content="Manage your Tensor Pilot profile, update personal information, handle subscriptions, and download the AI Drone Software." />
          <meta property="og:url" content="https://www.aidronesoftware.com/profile" />
          <meta property="og:site_name" content="Tensor Pilot - AI Drone Software" />
          <meta property="og:image" content={Logo} /> 
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:type" content="website" />

          {/* Twitter Card Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Profile - Tensor Pilot an AI Drone Software" />
          <meta name="twitter:description" content="Manage your profile, personal details, subscriptions, and download Tensor Pilot AI Drone Software." />
          <meta name="twitter:image" content={Logo} /> 

          {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <NavBarMain />
      <Container className="profile-container">
        <Row>
          <Col lg={5} md={5} sm={12}  className="mt-3">
            
            <Subscription 
              userSubscription={userSubscription}
            />
              
          </Col>

          <Col lg={7} md={7} sm={12} className="mt-3">
              {/* We are passing these as props since these fields are editable, except email */}
              <UserInformation 
                Firstname={Firstname} 
                Lastname={Lastname} 
                Dob={Dob}
                useremail={currentUser.email} />
              

              {UpdateUserDroneData?
                <UpdateUserDroneInformation 
                  setUpdateUserDroneData={setUpdateUserDroneData}
                  NumberOfDrones={NumberOfDrones}
                  FlyingExperience={FlyingExperience}
                  UserDroneList={UserDroneList}
                  setUserDroneList={setUserDroneList}
                />
                :<UserDroneInformation 
                  NumberOfDrones={NumberOfDrones}
                  FlyingExperience={FlyingExperience}
                  UserDroneList={UserDroneList}
                  setUpdateUserDroneData={setUpdateUserDroneData}
                />
              }

              {ChangePassword?
              <UpdateUserPassword 
                setChangePassword={setChangePassword}
              />
              :<UserPassword 
                setChangePassword={setChangePassword}
              />
              }
          </Col>
      </Row>
    </Container>
    </>
  )
}
