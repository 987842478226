import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Spinner } from 'react-bootstrap'; // Import necessary Bootstrap components
import { storage } from '../../firebase';  // Import the storage object
import { Link } from 'react-router-dom';
import './css/Blog.css'; // Adjust path as necessary

const RelatedArticles = ({ relatedArticles: relatedArticlesIdArray }) => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchBlogList = async () => {
      try {
        // Get a reference to the BlogList.json file
        const blogListRef = storage.ref('Blog/BlogList.json');

        // Get the download URL for the BlogList.json
        const url = await blogListRef.getDownloadURL();

        // Fetch the JSON content
        const response = await fetch(url);
        const allBlogs = await response.json();
        
        // filter data array for only the related articles
        const data = allBlogs.filter(blog => relatedArticlesIdArray.includes(blog.id))
        
        // Fetch download URLs for each blog image
        const blogsWithImageURLs = await Promise.all(
          data.map(async (blog) => {
            const imageRef = storage.refFromURL(blog.landscapeImage);
            const imageUrl = await imageRef.getDownloadURL();
            return { ...blog, image: imageUrl };
          })
        );

        // Update the state with the blog data containing image URLs
        setBlogs(blogsWithImageURLs);
      } catch (error) {
        console.error('Error fetching blog list or images:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
    fetchBlogList();
  }, [relatedArticlesIdArray]);

  return (
    <div className='mt-5'>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <h4 className="mb-4 blog-post-title">Related Articles</h4>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Row>
              {blogs.map((blog) => (
                <Col key={blog.id} lg={4} md={4} sm={12} className="mb-4">
                  <Card className="h-100">
                    <Card.Img variant="top" src={blog.image} alt={blog.title} className="card-img-top" />
                    <Card.Body>
                      <Card.Title>{blog.title}</Card.Title>
                      <Card.Text>{blog.excerpt}</Card.Text>
                      <Card.Text className="text-muted"><small>{blog.date}</small></Card.Text>
                      <div className="d-flex justify-content-center">
                        <Link to={`/blog/${blog.id}`} className="btn btn-outline-primary">
                          Read
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RelatedArticles;
