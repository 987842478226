import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faSchool, faBuilding } from '@fortawesome/free-solid-svg-icons';
import './css/UserTypesSection.css'; // Add this import for custom styles
import SchoolImage from './assets/SchoolImage.png'
import RetailImage from './assets/RetailImage.png'
import Enterprise from './assets/EnterpriseImage.png'

const UserTypesSection = () => {
    const userTypes = [
        {
            title: "Enthusiast",
            description: "Explore new heights with our AI-driven software, designed to enhance your drone flying experience, perfect for hobbyists and drone photographers.",
            icon: faShoppingCart,
            image: RetailImage,
            link: "/sales#individual"
        },
        {
            title: "Education",
            description: "Integrate our software into your educational programs to provide students with hands-on learning experiences in robotics and drone technology.",
            icon: faSchool,
            image: SchoolImage,
            link: "/sales#schooling"
        },
        {
            title: "Enterprise",
            description: "Leverage our AI software to optimize the use of your existing drone fleets for inspections, surveillance, and data collection, enhancing your operational efficiency.",
            icon: faBuilding,
            image: Enterprise,
            link: "/sales#enterprise"
        }
    ];

    return (
        <div className="user-types-section mt-5">
            <Container>
                <h3 className="text-center mb-4">Who is Our Software For?</h3>
                <Row className="mt-5">
                    {userTypes.map((userType, index) => (
                        <Col xs={12} sm={12} md={12} lg={12} xl={4} key={index} className="mb-4">
                            <Card className="h-100 user-type-card shadow-sm">
                                <Card.Img 
                                    variant='top'
                                    src={userType.image} 
                                    className="user-type-image" 
                                />
                                <Card.Body className="">
                                    <div className="icon-container mb-3">
                                        <FontAwesomeIcon icon={userType.icon} size="3x" className="text-primary" />
                                    </div>
                                    <Card.Title className="mb-2">{userType.title}</Card.Title>
                                    <Card.Text className="mb-4">{userType.description}</Card.Text>
                                    <Button 
                                        variant="outline-primary" 
                                        className="learn-more-button"
                                        href={userType.link}
                                    >
                                        Learn More
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default UserTypesSection;
