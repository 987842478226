import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap'; // Import Spinner
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import Helmet from 'react-helmet';
import { storage } from '../../firebase';  // Import the storage object
import { Link } from 'react-router-dom';
import './css/Blog.css'; // Adjust path as necessary

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
  }, []);

  useEffect(() => {
    const fetchBlogList = async () => {
      try {
        // Get a reference to the BlogList.json file
        const blogListRef = storage.ref('Blog/BlogList.json');

        // Get the download URL for the BlogList.json
        const url = await blogListRef.getDownloadURL();

        // Fetch the JSON content
        const response = await fetch(url);
        const data = await response.json();
        // Fetch download URLs for each blog image
        const blogsWithImageURLs = await Promise.all(
          data.map(async (blog) => {
            const imageRef = storage.refFromURL(blog.landscapeImage);
            const imageUrl = await imageRef.getDownloadURL();
            return { ...blog, image: imageUrl };
          })
        );

        // Update the state with the blog data containing image URLs
        setBlogs(blogsWithImageURLs);
      } catch (error) {
        console.error('Error fetching blog list or images:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
    fetchBlogList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Blog - Tensor Pilot</title>
        <meta name="description" content="Explore Tensor Pilot's blog for the latest updates, insights, and advancements in AI Drone Software. Stay informed about industry trends and new features." />
        <meta name="keywords" content="Tensor Pilot Blog, AI Drone Software, Drone Technology News, AI Drone Innovations, Autonomous Drones, Drone Software Updates" />
        <meta property="og:title" content="Blog - Tensor Pilot" />
        <meta property="og:description" content="Stay updated with Tensor Pilot's blog, offering insights on AI-driven drone technology, latest software features, and industry trends." />
        <meta property="og:url" content="https://www.aidronesoftware.com/blog" />
        <meta property="og:site_name" content="Tensor Pilot AI Drone Software Blog" />
        <meta property="og:image" content="/path-to-your-logo/Logo.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tensor Pilot Blog" />
        <meta name="twitter:description" content="Discover the latest in AI Drone Software with Tensor Pilot's blog, featuring updates, insights, and industry news." />
        <meta name="twitter:image" content="/path-to-your-logo/Logo.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      
      <NavBarMain />

      <Container fluid className="col-lg-8 col-md-10 col-11 blog-container mb-5">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h1 className="blog-page-title">Tensor Pilot Blog</h1>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                {blogs.length > 0 && (
                  <div className="featured-blog">
                    <img src={blogs[0].image} alt={blogs[0].title} className="featured-image" />
                    <div className="featured-content">
                      <h4 className="blog-post-title">{blogs[0].title}</h4>
                      <p className="blog-post-date">{blogs[0].date}</p>
                      <p className="blog-post-excerpt">{blogs[0].excerpt}</p>
                      <Link to={`/blog/${blogs[0].id}`} className="learn-more-button">
                        Learn more
                      </Link>
                    </div>
                  </div>
                )}
                <div className="blog-list">
                  {blogs.slice(1).map((blog) => (
                    <div key={blog.id} className="blog-post">
                      <img src={blog.image} alt={blog.title} className="blog-thumbnail" />
                      <div className="blog-content">
                        <h4 className="blog-post-title2">{blog.title}</h4>
                        <p className="blog-post-excerpt">{blog.excerpt}</p>
                        <p className="blog-post-date-align-left">{blog.date}</p>
                        <div>
                          <Link to={`/blog/${blog.id}`} className="learn-more-button">
                            Learn more
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Blog;
