import React, { useRef, useState } from "react"
import { Container, Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"


const UpdateUserPassword = ({setChangePassword}) => {

	const passwordRef = useRef()
	const passwordConfirmRef = useRef()
	
	const { updatePassword } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	function handleSubmit(e) {
	    e.preventDefault()
	    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
	      return setError("Passwords do not match")
	    }

	    const promises = []
	    setLoading(true)
	    setError("")

	    if (passwordRef.current.value) {
	      promises.push(updatePassword(passwordRef.current.value))
	    }
	  
	    Promise.all(promises)
	      .then(() => {
	        history.push("/profile")
	      })
	      .catch(() => {
	        setError("Failed to update account")
	      })
	      .finally(() => {
	        setLoading(false)
	        setChangePassword(false)
	      })
	}

	return (
	<Card className="mt-2">
	    <Card.Header  className="d-flex justify-content-between bg-primary text-white">
	       <h5  className="mb-0">
			Change Password
			</h5>
	    </Card.Header>
      	<Form onSubmit={handleSubmit}>
		    <Card.Body>
		        {error && <Alert variant="danger">{error}</Alert>}
		        <Row>
		          <Col md={6} lg={6} sm={12}>
		            <Form.Group id="password">
		              <Form.Label>Password</Form.Label>
		              <Form.Control
		                type="password"
		                ref={passwordRef}
		                placeholder="Leave blank to keep the same"
		              />
		            </Form.Group>
		          </Col>

		          <Col md={6} lg={6} sm={12}>
		            <Form.Group id="password-confirm">
		              <Form.Label>Password Confirmation</Form.Label>
		              <Form.Control
		                type="password"
		                ref={passwordConfirmRef}
		                placeholder="Leave blank to keep the same"
		              />
		            </Form.Group>
		          </Col>
		        </Row>
		    </Card.Body>
			
			{/* <Card.Footer className="d-flex justify-content-end">
                    <Button disabled={loading} className="mr-2" size="sm" type="submit">
                        Update
                    </Button>
                    <Button onClick={() => props.setUpdateUserDroneData(false)} size="sm" variant="danger">
                        Cancel
                    </Button>
			</Card.Footer> */}

	    	<Card.Footer className="d-flex justify-content-end">
    		    <Button 
					variant="outline-primary"
					className="float-right mr-2" 
					disabled={loading} 
					size="sm" 
					type="submit">
		          Update
		        </Button>
		        <Button className="float-right" onClick={()=>setChangePassword(false)} size="sm" variant="secondary">
		          Cancel
		        </Button>
		    </Card.Footer>
    	</Form>
  	</Card>
)}

export default UpdateUserPassword;
