import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faNewspaper} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import './css/CallToActionSection.css';

library.add(faApple, faWindows,faEnvelope, faNewspaper);

const CallToActionSection = (props) => {
    return (
        <Container className="cta-section">
            <Row className="justify-content-center">
                <Col lg={12} className="text-center">
                    <div className="cta-box p-5">
                        <h3>Engage with Our Community</h3>
                        <p>Contact us for custom integration and download the software for free on Windows or macOS.</p>
                        <Row className="justify-content-center mt-4">
                            
                            <Col xs={12} md={6} lg={4} className="mb-4">
                                <Button 
                                    variant="outline-primary" 
                                    size="lg" 
                                    className="cta-button"
                                    href="/contactus"
                                >
                                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                                    Contact Us
                                </Button>
                            </Col>
                            
                            <Col xs={12} md={6} lg={4} className="mb-4">
                                <Button 
                                    variant="outline-primary" 
                                    size="lg" 
                                    className="cta-button"
                                    onClick={props.handleDownloadRef}
                                    >
                                    <FontAwesomeIcon icon={faWindows} className="mr-2" />
                                    <FontAwesomeIcon icon={faApple} className="mr-2" />
                                    Free Download
                                </Button>
                            </Col>

                            <Col xs={12} md={6} lg={4} className="mb-4">
                                <Button 
                                    variant="outline-primary" 
                                    size="lg" 
                                    className="cta-button"
                                    href="/blog"
                                >   
                                    <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
                                    Read Blog
                                </Button>
                            </Col>

                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default CallToActionSection;
