import {useCallback, useState, useEffect} from 'react' 
import ReactPlayer from 'react-player/youtube'

const Youtubeplayer = (props) =>{
	return(
		<div style={playerWrapperCss}>
			<ReactPlayer
			  url={props.url}
			  width={props.width}
			  height={props.height}
			  style={reactPlayer}
			  controls={true}
	    	/>
	    </div>
	)
}

const playerWrapperCss =  {
  position: "relative",
  paddingTop: "56.25%",
  height: 0, 
  overflow: 'hidden'
}

const reactPlayer = {
  position: "absolute",
  top: "0",
  left: "0",
  width: '100%',
  height: '100%'
}


export default Youtubeplayer;