import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LogoImg from '../../assets/Logo-HighRes.png'; 

const UserNotLoggedInModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body style={modalBodyStyle}>
        <center>
          <h4 className="hero__headline">Tensor Pilot</h4>
        </center>
        <p className="slogan mt-2">Smart Flight Software for Drones.</p>
        <center>
          <img 
            src={LogoImg} 
            className="login-logo" 
            alt="Logo" 
          />
        </center>
        <div style={ctaContainerStyle}>
          <p style={ctaHeaderStyle}>Get started with our free basic version. Download it now!</p>
          <Link to="/signup">
            <Button variant="success" style={buttonStyle}>Sign Up</Button>
          </Link>
        </div>
        <div style={ctaContainerStyle}>
          <p style={ctaHeaderStyle}>Already have an account?</p>
          <Link to="/login">
            <Button variant="primary" style={buttonStyle}>Log In</Button>
          </Link>
        </div>
        <span
          onClick={handleClose}
          style={closeButtonStyle}>
          ╳
        </span>
      </Modal.Body>
    </Modal>
  );
};

const modalBodyStyle = {
  borderRadius: '15px',  // Softer, rounder corners
  padding: '40px',
  textAlign: 'center',
  color: 'black',
  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
};

const ctaContainerStyle = {
  backgroundColor: 'rgba(229, 229, 247, 0.5)',
  border: 'medium',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderRadius: '10px',
  margin: '20px 0',
};

const ctaHeaderStyle = {
  fontSize: '18px',
  fontWeight: '500',
};

const buttonStyle = {
  backgroundColor: '#fff',
  display: 'block',
  fontWeight: '800',
  borderRadius: '0.5rem',
  textDecoration: 'none',
  backgroundImage: 'linear-gradient(45deg, hsl(211, 83%, 54%), hsl(2, 87%, 64%))',
}

const closeButtonStyle = {
  cursor: 'pointer',
  marginTop: '20px',
  fontWeight: '800',
  border: 'none',
  fontWeight: 'bold',
  position: 'absolute',
  top: '5px',  
  right: '20px',
};


export default UserNotLoggedInModal;
