import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {faUniversalAccess, 
    faLightbulb, 
    faSmileBeam, 
    faHandSparkles, 
    faVideo,
    faGamepad,
    faGavel,
    faKeyboard,
    faCog, 
    faIcons,
    faRoute,
    faSatellite} from '@fortawesome/free-solid-svg-icons';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import FreeSubscriptionCard from './SubscriptionCardInfo';

const Enterprise = () => {
    
    const enterpriseFeatures = [
        { text: "AI - Object Detection", available: true, iconName: faUniversalAccess},
        { text: "AI - Human Pose Detection", available: true, iconName:faLightbulb },
        { text: "AI - Face Mesh", available: true, iconName: faSmileBeam },
        { text: "AI - Hand Gesture", available: true, iconName: faHandSparkles},
        { text: "AI - Object Tracking", available: true, iconName: faSatellite},
        { text: "Predefined Flight Path", available: true, iconName: faRoute},
        { text: "Multiple Cameras Feed", available: true, iconName: faIcons},
        { text: "Cinematography Modes", available: true, iconName: faVideo },
        { text: "Remote Control", available: true, iconName:faGamepad },
        { text: "Keyboard Control", available: true, iconName:faKeyboard },
        { text: "Non Commercial License", available: true, iconName:faGavel  },
        { text: "Compatible with Apple", available: true, iconName:faApple  },
        { text: "Compatible with Windows", available: true, iconName:faWindows  },
        { text: "Software Upgrades", available: true, iconName:faCog  },
      ];
    
  return (
    <div id="enterprise" className="mt-2">
      <Row className="justify-content-center">
        <Col md={7} lg={7} sm={12} className="d-flex align-items-center">
            <div className='text-justify'>
                <h4>Custom Drone Compatibility</h4>
                <p>
                Our software is highly flexible and can be modified to be compatible with any drone your enterprise uses. Bring your drone to us, and we will tailor our software to meet your specific needs, ensuring seamless integration and optimal performance.
                </p>
                <p>
                Whether you require advanced AI capabilities, customized flight paths, or unique control options, our team is ready to adapt our software to fit your requirements. We understand that every enterprise has unique needs, and we are here to provide the best solutions for your drone operations.
                </p>
                <p>
                <a href="/contactus">Contact us</a> today to discuss how we can help you achieve your goals with custom drone software solutions.
                </p>
            </div>
        </Col>

        <Col md={5} lg={5} sm={12}>
          <FreeSubscriptionCard
              title="Tensor Pilot Enterprise"
              description="Advance softwares for your drone"
              features={enterpriseFeatures}
              onButtonClick={() => window.location.href = '/contactus'}
              buttonText="Get In Touch"
            />
        </Col>
      </Row>
    </div>
  );
};

export default Enterprise;
