import React, { useRef, useState, useEffect } from "react";
import { Table, Form, Button, Card, Alert, Row, Col } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { database } from '../../firebase';
import './css/UpdateUserDroneInformation.css';

library.add(faUserCircle, faTimes);

const UpdateUserDroneInformation = (props) => {
    const { currentUser } = useAuth();

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [numberOfDrones, setNumberOfDrones] = useState(props.UserDroneList.length);
    const history = useHistory();

    const flyingexperienceinput = useRef();

    const [SelectADroneError, setSelectADroneError] = useState("");
    const DroneMarketList = [
        { key: 0, name: "Select Drone" },
        { key: 1, name: "DJI Mavic 3 Pro" },
        { key: 2, name: "DJI Air 2S" },
        { key: 3, name: "DJI Mini 3 Pro" },
        { key: 4, name: "DJI Mini 4 Pro" },
        { key: 5, name: "DJI Avata" },
        { key: 6, name: "DJI FPV" },
        { key: 7, name: "DJI Inspire 2" },
        { key: 8, name: "Autel Robotics EVO II" },
        { key: 9, name: "Autel EVO Lite+" },
        { key: 10, name: "Autel EVO Nano+" },
        { key: 11, name: "Skydio 2" },
        { key: 12, name: "Skydio X2" },
        { key: 13, name: "Parrot Anafi" },
        { key: 14, name: "Parrot Bebop 2" },
        { key: 15, name: "Holy Stone HS720" },
        { key: 16, name: "Holy Stone HS100" },
        { key: 17, name: "Ryze Tello" },
        { key: 18, name: "PowerVision PowerEgg X" },
        { key: 19, name: "Yuneec Typhoon H Pro" },
        { key: 20, name: "BetaFPV Cetus" },
        { key: 21, name: "Other" }
    ];

    const [addDrone, setAddDrone] = useState("");
    const [OtherDroneName, setOtherDroneName] = useState("");

    // useEffect(() => {
    //     setNumberOfDrones(props.UserDroneList.length);
    // }, [props.UserDroneList]);

    const deleteDroneName = (index) => {
        props.setUserDroneList(props.UserDroneList.filter((task) => task.index !== index));
    };

    const addANewDrone = () => {
        setSelectADroneError("");
        if (addDrone === "Select Drone" || addDrone === "") {
            setSelectADroneError("Please select a drone");
        } else {
            let droneNameToBeAdded = addDrone === "Other" ? OtherDroneName : addDrone;
            if (props.UserDroneList.some(drone => drone.dronename === droneNameToBeAdded)) {
                setSelectADroneError("You have already added this drone.");
            } else {
                const task = {
                    index: props.UserDroneList.length + 1,
                    dronename: droneNameToBeAdded
                };
                props.setUserDroneList([...props.UserDroneList, task]);
                setAddDrone("");
                setOtherDroneName("");
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const promises = [];
        setLoading(true);
        setError("");

        const newFlyingExperience = flyingexperienceinput.current.value;

        // Check if values have changed
        const updates = {};
        if (newFlyingExperience !== props.FlyingExperience.toString()) {
            updates.flyingexperience = newFlyingExperience;
        }
		updates.numberofdronesyouhave = props.UserDroneList.length;
        
		if (props.UserDroneList !== updates.userdronelist) {
            updates.userdronelist = props.UserDroneList;
        }
		if (Object.keys(updates).length > 0) {
            promises.push(database.users.doc(currentUser.uid).update(updates));
        }

        Promise.all(promises)
            .then(() => {
                // Additional actions can be placed here
            })
            .catch((err) => {
                setError("Failed to update account: " + err.message);
            })
            .finally(() => {
                setLoading(false);
                props.setUpdateUserDroneData(false);
            });
    };

    return (
        <Card className="mt-2">
            <Card.Header className="bg-primary text-white">
                <h5>Your Drones</h5>
            </Card.Header>
            <Form onSubmit={handleSubmit}>
                <Card.Body>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Row>
                        <Col md={6} lg={6} sm={12}>
                            <Form.Group id="flyingexperience">
                                <Form.Label>Years of Drone flying experience</Form.Label>
                                <Form.Control
                                    as="select"
                                    ref={flyingexperienceinput}
                                    defaultValue={props.FlyingExperience}
                                    required
                                >
                                    {[...Array(100).keys()].map((year) => (
                                        <option key={year + 1} value={year + 1}>{year + 1}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group id="numberofdrones">
                                <Form.Label>Number of Drones you have</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={numberOfDrones}
                                    readOnly
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={12} sm={12}>
                            {/* <div className="bg-info text-white p-2 rounded mt-3">Your Drone List</div> */}
                            {SelectADroneError && <Alert variant="danger" className="mt-2">{SelectADroneError}</Alert>}
                            {props.UserDroneList && props.UserDroneList.length ? (
                                <Table size="sm" bordered={false} className="table-hover mt-2">
									<thead className="thead-light">
									<tr>
										<th colSpan="2" className="text-left">Your Drone List</th>
									</tr>
									</thead>
									<tbody>
									{props.UserDroneList.map((droneitem, i) => (
										<tr key={i} className="align-middle">
										<td>{droneitem.dronename}</td>
										<td className="text-right">
											<Button
											variant="danger"
											size="sm"
											onClick={() => deleteDroneName(droneitem.index)}
											>
											<FontAwesomeIcon icon="times" />
											</Button>
										</td>
										</tr>
									))}
									</tbody>
								</Table>
                            ) : (
                                <Alert variant="info" className="mt-2">You have no drones added.</Alert>
                            )}
                            <Form.Group className="mt-3">
                                <Form.Label>Add a drone</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={addDrone}
                                    onChange={e => setAddDrone(e.target.value)}
                                    size="sm"
                                    custom
                                >
                                    {DroneMarketList.map((drone, i) => (
                                        <option key={i} value={drone.name}>{drone.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            {addDrone === "Other" && (
                                <Form.Group>
                                    <Form.Label>Other Drone Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={OtherDroneName}
                                        onChange={e => setOtherDroneName(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            )}
                            <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={addANewDrone}
                                className="mt-2"
                            >
                                Add new drone
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-end">
                    <Button 
					variant="outline-primary"
					disabled={loading} 
					className="mr-2" 
					size="sm" 
					type="submit">
                        Update
                    </Button>
                    <Button onClick={() => props.setUpdateUserDroneData(false)} size="sm" variant="secondary">
                        Cancel
                    </Button>
                </Card.Footer>
            </Form>
        </Card>
    );
};

export default UpdateUserDroneInformation;
