import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversalAccess, faLightbulb, faSmileBeam, faHandSparkles } from '@fortawesome/free-solid-svg-icons';

import ObjectDetection from './assets/ObjectDetection.png';
import HandLandMarkDetection from './assets/HandLandMarkDetection.png';
import HumanPoseDetection from './assets/HumanPoseDetection.png';
import FaceMesh from './assets/FaceMesh.png';

import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';  // Import AOS styles

import './css/AIFeaturesDisplay.css';

const features = [
    {
        id: 'object-detection',
        title: "Object Detection",
        description: [
            "Real-time object identification and categorization.",
            "Enhances drone operational efficiency.",
            "Facilitates immediate response and decision-making."
        ],
        icon: faLightbulb,
        color: '#FFD700', // Gold
        gif: ObjectDetection
    },
    {
        id: 'pose-detection',
        title: "Pose Detection",
        description: [
            "Provides precise body pose landmarks.",
            "Essential for crowd management and rescue operations.",
            "Supports applications in sports training and event monitoring."
        ],
        icon: faUniversalAccess,
        color: '#4CAF50', // Green
        gif: HumanPoseDetection
    },
    {
        id: 'face-landmark-detection',
        title: "Face Landmark Detection",
        description: [
            "Detects human faces and key facial landmarks.",
            "Enables user identification and crowd monitoring.",
            "Enhances advanced drone functionalities."
        ],
        icon: faSmileBeam,
        color: '#2196F3', // Blue
        gif: FaceMesh
    },
    {
        id: 'hand-gesture-recognition',
        title: "Hand Gesture Recognition",
        description: [
            "Interprets specific hand gestures in real-time.",
            "Allows intuitive control and interaction without physical contact.",
            "Enhances user experience and operational flexibility."
        ],
        icon: faHandSparkles,
        color: '#FF5722', // Deep Orange
        gif: HandLandMarkDetection
    }
];

const AIFeaturesDisplay = () => {

    // Initialize AOS
    useEffect(() => {
        AOS.init({ duration: 1200, once: true });
    }, []);

    return (
        <div className="mt-5 ai-feature">
            {/* Glassmorphic background */}
            <div className="glassmorphic-background"></div>
            
            <Row className='mt-4 ai-feature-description feature-container'>
                <Col className='text-center mb-4'>
                    <h1 className='gradient-text'>Advanced AI Capabilities for Your Drone</h1>
                </Col>

                {features.map((ai_feature, index) => {
                    const isEven = index % 2 === 0;

                    // Determine AOS animation based on index
                    const aosAnimation = isEven ? 'fade-right' : 'fade-left';

                    return (
                        <Col lg={12} md={12} sm={12} className='mb-1 mt-1' key={ai_feature.id}>
                            <Row 
                                id={ai_feature.id}
                                className="feature-container d-flex justify-content-center align-items-center"
                                data-aos={aosAnimation}  // Add AOS animation
                            >   
                                {/* Image Column for Desktop */}
                                <Col 
                                    xs={12} sm={12} md={12} lg={12} xl={7} 
                                    className={`mb-2 ${isEven ? 'order-md-1' : 'order-md-2'} d-none d-md-block`}
                                >
                                    <div className="image-container flex-fill">
                                        <img 
                                            src={ai_feature.gif} 
                                            alt={`${ai_feature.title} gif`} 
                                            className="rounded-img"
                                        />
                                    </div>
                                </Col>

                                {/* Text Column */}
                                <Col 
                                    xs={12} sm={12} md={12} lg={12} xl={5} 
                                    className={`mb-2 ${isEven ? 'order-md-2' : 'order-md-1'}`}
                                >
                                    <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
                                        {/* Icon and Title Container */}
                                        <div className="d-flex flex-row flex-md-column align-items-center mb-2 w-100">
                                            {/* Icon */}
                                            <div className="me-2 mb-0 mb-md-2 pr-2">
                                                <FontAwesomeIcon icon={ai_feature.icon} size="3x" style={{ color: ai_feature.color }} />
                                            </div>
                                            
                                            {/* Title */}
                                            <div>
                                                <h4 className="text-muted">{ai_feature.title}</h4>
                                            </div>
                                        </div>
                                        
                                        {/* Image for Mobile */}
                                        <div className="my-2 d-block d-md-none w-100">
                                            <div className="image-container flex-fill">
                                                <img 
                                                    src={ai_feature.gif} 
                                                    alt={`${ai_feature.title} gif`} 
                                                    className="rounded-img"
                                                />
                                            </div>
                                        </div>
                                        
                                        {/* List */}
                                        <div className="w-100">
                                            <ul className="feature-list sub-text">
                                                {ai_feature.description.map((point, idx) => (
                                                    <li key={idx}>{point}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    )
                })}
            </Row>
        </div>
    );
}

export default AIFeaturesDisplay;
