import React from 'react';
import { Container, Card } from 'react-bootstrap';
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import Helmet from 'react-helmet';
import './css/PrivacyPolicy.css';
import Logo from '../../assets/Logo.png';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>Privacy Policy - Tensor Pilot</title>

        {/* Meta Description for SEO */}
        <meta name="description" content="Read the Privacy Policy for Tensor Pilot AI Drone Software. Learn how we handle your personal data and protect your privacy while using our drone software." />

        {/* SEO Keywords */}
        <meta name="keywords" content="Privacy Policy, Data Protection, AI Drone Software Privacy, Tensor Pilot Privacy, User Data Security, Drone Software Privacy Policy, Personal Data, Information Security, Privacy Practices, AI Software Privacy, Drone Technology, User Privacy Rights" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Privacy Policy - Tensor Pilot AI Drone Software" />
        <meta property="og:description" content="Review the Privacy Policy of Tensor Pilot AI Drone Software to understand how we collect, use, and protect your personal information." />
        <meta property="og:url" content="https://www.aidronesoftware.com/privacy-policy" />
        <meta property="og:site_name" content="Tensor Pilot - AI Drone Software" />
        <meta property="og:image" content={Logo} /> 
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacy Policy - Tensor Pilot an AI Drone Software" />
        <meta name="twitter:description" content="Understand how Tensor Pilot AI Drone Software handles your data and protects your privacy. Read our comprehensive Privacy Policy." />
        <meta name="twitter:image" content={Logo} />

        {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <NavBarMain />
      <Container className="privacy-container">
        <Card className="privacy-card">
          <Card.Body>
            <Card.Title>
                <h2>Privacy Policy</h2>
            </Card.Title>
            <Card.Text>
              <h5>1. Introduction</h5>
              <p>
                Welcome to AI Drone Software. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services. By using our services, you agree to the terms of this Privacy Policy.
              </p>
              <h5>2. Information We Collect</h5>
              <p>
                We may collect personal information that you provide to us directly, such as your name, email address, and payment information. We may also collect information about your use of our services, including log data and usage statistics.
              </p>
              <h5>3. How We Use Your Information</h5>
              <p>
                We use your information to provide and improve our services, process transactions, communicate with you, and for security purposes. We may also use your information to personalize your experience and to send you promotional materials.
              </p>
              <h5>4. Information Sharing and Disclosure</h5>
              <p>
                We do not share your personal information with third parties except as necessary to provide our services, comply with the law, or protect our rights. We may share anonymized or aggregated data with third parties for research and analysis.
              </p>
              <h5>5. Data Security</h5>
              <p>
                We implement appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no security system is impenetrable, and we cannot guarantee the security of our systems 100%.
              </p>
              <h5>6. Your Rights</h5>
              <p>
                You have the right to access, update, or delete your personal information. You may also object to the processing of your data or request that we restrict the processing of your data in certain circumstances.
              </p>
              <h5>7. Changes to This Privacy Policy</h5>
              <p>
                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.
              </p>
              <h5>8. Contact Us</h5>
              <p>
                If you have any questions about this Privacy Policy, please contact us at support@aidronesoftware.com.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
