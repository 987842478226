import React from 'react';
import { Card, Button, Row, Col, Spinner } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './css/SubscriptionCard.css';

library.add(faCheckCircle, faTimesCircle);

const getSubscriptionDetails = (subscription) => {
  if (!subscription || !subscription.items || !subscription.items.length) {
    return {};
  }

  const item = subscription.items[0];
  const priceId = item.price.id;
  const interval = item.price.recurring.interval;
  const intervalCount = item.price.recurring.interval_count;

  return { priceId, interval, intervalCount };
};

const PaidSubscriptionCard = ({ title, features, products, onButtonClick, popular, loading, clickedPriceId, userSubscription }) => {
  const subscriptionDetails = getSubscriptionDetails(userSubscription);

  return (
    <Card className="shadow-sm glassmorphic-card">
      <Card.Header className={`bg-primary text-white ${popular ? 'position-relative' : ''}`}>
        {popular && <div className="badge bg-warning text-dark position-absolute" style={{ top: '10px', right: '10px' }}>Most Popular</div>}
        <h5>{title}</h5>
        <p>Advanced Capabilities with Tensor Pilot AI</p>
      </Card.Header>
      <Card.Body>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className={`feature-item ${feature.available ? 'available' : 'unavailable'}`}>
              <FontAwesomeIcon className="feature-icon" icon={feature.iconName} />
              <span className="feature-text">{feature.text}</span>
              <FontAwesomeIcon className="status-icon ml-1" icon={feature.available ? faCheckCircle : faTimesCircle} />
            </div>
          ))}
        </div>
      </Card.Body>
      <Card.Footer className="bg-transparent">
        <center>
          {products.map(product => (
            <React.Fragment key={product.id}>
              {product.prices.map(price => {
                const isCurrentPlan = subscriptionDetails.priceId === price.priceId;
                const isDowngrade = (subscriptionDetails.interval === 'month' && subscriptionDetails.intervalCount === 6) && price.interval === 'monthly';
                const isUpgrade = subscriptionDetails.interval === 'month' && subscriptionDetails.intervalCount === 1 && price.interval === 'sixMonths';
                
                const buttonText = isCurrentPlan
                  ? 'Purchased'
                  : isUpgrade
                    ? 'Upgrade'
                    : isDowngrade
                      ? 'Schedule Downgrade'
                      : price.interval === 'monthly'
                        ? `Buy for $${price.amount} / Month`
                        : `Buy for $${price.amount} / 6 Months`;

                return (
                  <Row key={`${product.id}-${price.priceId}`} className="">
                    <Col lg={12} md={12} sm={12} key={price.priceId}>
                      <Button
                        className="custom-button-paid-sub m-2 button-80"
                        variant="outline-primary"
                        onClick={() => onButtonClick(price.priceId, 'subscription', product.name)}
                        disabled={loading && clickedPriceId === price.priceId || isCurrentPlan}
                      >
                        {loading && clickedPriceId === price.priceId ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          buttonText
                        )}
                      </Button>
                    </Col>
                  </Row>
                );
              })}
            </React.Fragment>
          ))}
        </center>
      </Card.Footer>
    </Card>
  );
};

export default PaidSubscriptionCard;
