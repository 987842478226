// src/components/DownloadSection/DownloadSection.js

import React, { useState, useRef } from 'react';
import { Button, Spinner, Modal, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import './css/PublicDownload.css'; // Import custom styles if any

// Google reCAPTCHA Site Key
const RECAPTCHA_SITE_KEY = '6LcjnS4bAAAAAI3HKCTce9dAnOucKEalagR65dtr'; // Replace with your actual Site Key

const PublicDownload = () => {
    const [isDownloadingMac, setIsDownloadingMac] = useState(false);
    const [isLoggingWindows, setIsLoggingWindows] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertVariant, setAlertVariant] = useState('success');
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [downloadType, setDownloadType] = useState(null); // 'MacIntel', 'MacARM', 'Windows'
    const [showAlertModal, setShowAlertModal] = useState(false);

    const recaptchaRef = useRef(null);

    // Handler for initiating download with Captcha
    const initiateDownload = (type) => {
        setDownloadType(type);
        setShowCaptcha(true);
    };

    // Handler for Captcha verification success
    const onCaptchaSuccess = async (token) => {
        setShowCaptcha(false);
        if (!downloadType) {
            setAlertMessage('Invalid download type.');
            setAlertVariant('danger');
            setShowAlertModal(true);
            return;
        }

        if (downloadType.startsWith('Mac')) {
            const arch = downloadType === 'MacIntel' ? 'intel' : 'arm';
            await handleDownloadMac(arch, token);
        } else if (downloadType === 'Windows') {
            await handleDownloadWindows(token);
        }

        setDownloadType(null);
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    };

    // Handler for Captcha verification failure or expiration
    const onCaptchaFailure = () => {
        setShowCaptcha(false);
        setDownloadType(null);
        setAlertMessage('Captcha verification failed. Please try again.');
        setAlertVariant('danger');
        setShowAlertModal(true);
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    };

    // Handler for Mac Downloads
    const handleDownloadMac = async (arch, captchaToken) => {
        setIsDownloadingMac(true);
        setAlertMessage(null);

        try {
            const response = await axios.get('/api/public/download', {
                headers: {
                    Platform: 'Mac',
                    Arch: arch,
                    'Captcha-Token': captchaToken, // Send Captcha token to backend
                },
            });

            const { url } = response.data;

            // Trigger the download
            const a = document.createElement('a');
            a.href = url;
            a.download = ''; // Optional: specify a default filename
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            setAlertMessage('Download started successfully.');
            setAlertVariant('success');
            setShowAlertModal(true);
        } catch (error) {
            console.error('Error downloading the Mac application:', error);
            setAlertMessage('Failed to start the download. Please try again later.');
            setAlertVariant('danger');
            setShowAlertModal(true);
        } finally {
            setIsDownloadingMac(false);
        }
    };

    // Handler for Windows Downloads
    const handleDownloadWindows = async (captchaToken) => {
        setIsLoggingWindows(true);
        setAlertMessage(null);

        try {
            // Log the download event with Captcha token
            await axios.post(
                '/api/public/logDownload',
                {
                    platform: 'Windows',
                },
                {
                    headers: {
                        'Captcha-Token': captchaToken, // Send Captcha token in headers
                    },
                }
            );

            // Redirect to Windows Store
            const newWindow = window.open(
                'https://apps.microsoft.com/store/detail/9P7MRSZSCWNN?cid=DevShareMCLPCS',
                '_blank'
            );

            if (newWindow === null) {
                // Pop-up was blocked
                setAlertMessage(
                    <>
                        <p>
                            Pop-up was blocked! Please allow pop-ups for this site or click the link below
                            to navigate to Windows Store.
                        </p>
                        <p>
                            <a
                                href="https://apps.microsoft.com/store/detail/9P7MRSZSCWNN?cid=DevShareMCLPCS"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Go to Microsoft Store
                            </a>
                        </p>
                    </>
                );
                setAlertVariant('danger');
                setShowAlertModal(true);
            } else {
                setAlertMessage('Redirecting to Windows Store...');
                setAlertVariant('success');
                setShowAlertModal(true);
            }
        } catch (error) {
            console.error('Error logging the Windows download event:', error);
            setAlertMessage(
                <>
                    <p>
                        Something went wrong, you will still be redirected. If the redirection
                        didn't work, please click the link below:
                    </p>
                    <p>
                        <a
                            href="https://apps.microsoft.com/store/detail/9P7MRSZSCWNN?cid=DevShareMCLPCS"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Go to Microsoft Store
                        </a>
                    </p>
                </>
            );
            setAlertVariant('warning');
            setShowAlertModal(true);

            // Proceed with redirection even if logging fails
            const newWindow = window.open(
                'https://apps.microsoft.com/store/detail/9P7MRSZSCWNN?cid=DevShareMCLPCS',
                '_blank'
            );

            if (newWindow === null) {
                // Pop-up was blocked
                setAlertMessage(
                    <>
                        <p>
                            Pop-up was blocked! Please allow pop-ups for this site and click the link below
                            to proceed.
                        </p>
                        <p>
                            <a
                                href="https://apps.microsoft.com/store/detail/9P7MRSZSCWNN?cid=DevShareMCLPCS"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Go to Microsoft Store
                            </a>
                        </p>
                    </>
                );
                setAlertVariant('danger');
                setShowAlertModal(true);
            }
        } finally {
            setIsLoggingWindows(false);
        }
    };

    return (
        <div className="download-section my-4">
            <Row className='mb-4 text-center mt-1 mb-5'>
                <Col>
                    <h1 className='gradient-text'>Download Software</h1>
                </Col>
            </Row>

            {/* Download Cards */}
            <Row className="justify-content-center">
                {/* Mac Intel Download Card */}
                <Col xs={12} md={4} lg={4} className="mb-4">
                    <Card className="text-center shadow-sm h-100">
                        <Card.Body className="d-flex flex-column align-items-center">
                            <FontAwesomeIcon
                                icon={faApple}
                                size="3x"
                                className="mb-3 apple-icon"
                            />
                            <Card.Title>Mac OS (Intel)</Card.Title>
                            <Button
                                variant="outline-primary"
                                className="mt-auto btn-download"
                                onClick={() => initiateDownload('MacIntel')}
                                disabled={isDownloadingMac || isLoggingWindows}
                            >
                                {isDownloadingMac ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        Downloading...
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faDownload} /> Download
                                    </>
                                )}
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Mac ARM Download Card */}
                <Col xs={12} md={4} lg={4} className="mb-4">
                    <Card className="text-center shadow-sm h-100">
                        <Card.Body className="d-flex flex-column align-items-center">
                            <FontAwesomeIcon
                                icon={faApple}
                                size="3x"
                                className="mb-3 apple-icon"
                            />
                            <Card.Title>Mac OS (ARM)</Card.Title>
                            <Button
                                variant="outline-primary"
                                className="mt-auto btn-download"
                                onClick={() => initiateDownload('MacARM')}
                                disabled={isDownloadingMac || isLoggingWindows}
                            >
                                {isDownloadingMac ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        Downloading...
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faDownload} /> Download
                                    </>
                                )}
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Windows Download Card */}
                <Col xs={12} md={6} lg={4} className="mb-4">
                    <Card className="text-center shadow-sm h-100">
                        <Card.Body className="d-flex flex-column align-items-center">
                            <FontAwesomeIcon
                                icon={faWindows}
                                size="3x"
                                className="mb-3 windows-icon"
                            />
                            <Card.Title>Windows</Card.Title>
                            <Button
                                variant="outline-primary"
                                className="mt-auto btn-download"
                                onClick={() => initiateDownload('Windows')}
                                disabled={isDownloadingMac || isLoggingWindows}
                            >
                                {isLoggingWindows ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        Redirecting...
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faDownload} /> Download
                                    </>
                                )}
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Google reCAPTCHA Modal */}
            <Modal show={showCaptcha} onHide={() => setShowCaptcha(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <p>
                        Please complete the CAPTCHA to proceed with the download. By solving the captcha you agree to our {' '}
                        <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                    Terms & Conditions
                                    </a>{' '}
                                    and{' '}
                                    <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                    Privacy Policy
                        </a>
                        <ReCAPTCHA
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={onCaptchaSuccess}
                            onErrored={onCaptchaFailure}
                            onExpired={onCaptchaFailure}
                            ref={recaptchaRef}
                        />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCaptcha(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Alert Modal */}
            <Modal
                show={showAlertModal}
                onHide={() => {
                    setShowAlertModal(false);
                    setAlertMessage(null);
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {alertMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowAlertModal(false);
                            setAlertMessage(null);
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PublicDownload;
