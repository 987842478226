import React from "react";
import { Table, Card, Row, Col, Alert } from "react-bootstrap";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './css/UpdateUserDroneInformation.css'

library.add(faUserCircle, faPencilAlt);

const UserDroneInformation = ({ setUpdateUserDroneData, NumberOfDrones, FlyingExperience, UserDroneList }) => {
    return (
        <Card className="mt-4 shadow-sm">
            <Card.Header className="d-flex justify-content-between align-items-center bg-primary text-white">
                <h5 className="mb-0">Your Drones</h5>
                <FontAwesomeIcon 
                    icon="pencil-alt" 
                    className="cursor-pointer" 
                    onClick={() => setUpdateUserDroneData(true)} 
                />
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col md={6} lg={6} sm={12}>
                        <p><strong>Years of Drone Flying Experience:</strong> <span className="text-muted">{FlyingExperience}</span></p>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                        <p><strong>Number of Drones:</strong> <span className="text-muted">{NumberOfDrones}</span></p>
                    </Col>
                    <Col md={12} lg={12} sm={12}>
						{UserDroneList && UserDroneList.length ? (
                            <Table size="sm" bordered={false} className="table-hover mt-3">
                                <thead className="thead-light">
                                    <tr>
                                        <th>Drone Names</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {UserDroneList.map((droneitem, i) => (
                                        <tr key={i} className="align-middle">
                                            <td>{droneitem.dronename}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <Alert variant="info" className="mt-3">You have no drones added.</Alert>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default UserDroneInformation;
