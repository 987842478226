import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import './css/ConsentModal.css'; // Import the CSS file for styling

const ConsentModal = ({ show, handleAccept, handleDecline }) => {
  const [showMore, setShowMore] = useState(false);

  const handleSeeMore = () => {
    setShowMore(true);
  };

  // Do not render anything if the `show` prop is false
  if (!show) return null;

  return (
    <div className="consent-banner">
      <div className="consent-content">
        <h5>We Value Your Privacy</h5>
        <p>
          {showMore ? (
            <>
              We use cookies and Google Analytics to ensure you have the best experience on our site. These tools help us 
              understand how our site is being used, enabling us to enhance your experience. Without your consent, some 
              features may not function properly, and your experience may be less personalized.
              <br /><br />
              By clicking "Accept", you allow us to track your interactions to improve site functionality and enhance 
              your experience. If you choose "Decline", certain features and functionalities may not be available to you.
            </>
          ) : (
            "We use cookies and similar technologies to improve your experience. By clicking \"Accept\", you consent to the use of these technologies for analytics and marketing purposes."
          )}
        </p>
        <div className="consent-actions">
          <Button variant="primary" onClick={handleAccept}>
            Accept
          </Button>
          {showMore ? (
            <Button variant="link" onClick={handleDecline} style={{ color: 'gray' }}>
              Decline
            </Button>
          ) : (
            <Button variant="link" onClick={handleSeeMore}>
              See More
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsentModal;
