import {Container, Row, Col, Button} from 'react-bootstrap';
import NavBarMain from '../../sharedcomponents/navbar/Navbar'
import Helmet from 'react-helmet';

const Unsubscribe = () => {
	return (
	<>
	<Helmet>
          <title>Unsubscribe - AI Drone Software </title>
          <meta name="description" content="Unsubscibe from Tensor Pilot marekting and product update emails." />
          <meta name="keywords" content="Tensor Pilot, AI Drone Software, Drones AI, Drone AI Software, Drone Software, AI Drone"/>
          <meta property="og:title" content="Unsubscibe - AI Drone Software " />
          <meta property="og:description" name="description" className="swiftype" content="Unsubscibe from Tensor Pilot marekting and product update emails."/>
          <meta property="og:url" content="https://www.aidronesoftware.com/team"/>
          <meta property="og:site_name" content="Unsubscibe - AI Drone Software "/>
        </Helmet>
		<NavBarMain />
	    <div className="not-found-page d-flex flex-column">
        <Container className="text-center my-5">
        <Row>
            <Col>
            <h3>We're sorry to see you go :(</h3>
            <p className="lead">Let us know what we did wrong, please use the Contact Us to unsubscribe from marketing and product update emails.</p>
            <Button 
                variant="outline-primary" 
                href="/contactus"
            >
                Go to Contact Us
            </Button>
            </Col>
        </Row>
        </Container>
        </div>
   	</> 
	)
}

export default Unsubscribe;