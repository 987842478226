import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../Home/css/CallToActionSection.css';


const BlogPostBottom = () => {
    return (
        <Container className="mt-5 mb-5">
            <Row className="justify-content-center">
                <Col lg={12} className="text-center">
                    <div className="cta-box p-5">
                        <h3>Want to know more about us?</h3>
                        <p>Contact us or get to know about our plans</p>
                        <Row className="justify-content-center mt-4">
                            <Col xs={12} md={6} lg={4} className="mb-4">
                                <Button 
                                    variant="outline-primary" 
                                    size="lg" 
                                    className="cta-button"
                                    href="/sales"
                                >
                                    See Pricing
                                </Button>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <Button 
                                    variant="outline-primary" 
                                    size="lg" 
                                    className="cta-button"
                                    href="/contactus"
                                    >
                                    Contact Us
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default BlogPostBottom;
