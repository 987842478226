import React, { useRef, useState } from "react"
import { Container, Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import { library } from '@fortawesome/fontawesome-svg-core'
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faPencilAlt)

const UserPassword = ({setChangePassword}) => {

	return (
		<Card className="mt-4 shadow-sm">
            <Card.Header className="d-flex justify-content-between align-items-center bg-primary text-white">
                <h5 className="mb-0">Update Password</h5>
                <FontAwesomeIcon 
                    icon="pencil-alt" 
                    className="cursor-pointer" 
                    onClick={() => setChangePassword(true)} 
                />
            </Card.Header>
      	
		<Card.Body>
		    <Row>
				<Col md={6} lg={6} sm={12}>
	        		<p>Password: ************</p>
	          	</Col>
			</Row>
			</Card.Body>
  	</Card>
)}

export default UserPassword;
