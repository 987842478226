import React, { useRef, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import Youtubeplayer from '../../sharedcomponents/youtubeplayer/youtubeplayer';
import { Button } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHeadset, faUniversalAccess, faLightbulb, faVideo, faStar, faRocket, faBrain, faCogs, faToolbox, faDownload } from '@fortawesome/free-solid-svg-icons';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AIFeaturesDisplay from './AIFeaturesDisplay';
import CinematographyFeatures from './CinematographyFeatures';
import DroneControlOptions from './DroneControlOptions';
import UserTypesSection from './UserTypesSection';
import CallToActionSection from './CallToActionSection';
import YoutubeVideoSection from './YoutubeVideoSection';
import PublicDownload  from './PublicDownload';
import FAQ from './FAQ';
import Helmet from 'react-helmet';
import Logo from '../../assets/Logo.png';

import './css/Home.css';

library.add(faApple, faWindows, faHeadset, faUniversalAccess, faLightbulb, faVideo, faStar, faRocket, faBrain, faCogs, faToolbox, faDownload);

function TypingEffect() {
    const [i, setI] = useState(0);
    const txt = 'Tensor Pilot: Where Imagination Takes Flight';
    const speed = 50;

    useEffect(() => {
        const typeWriter = () => {
            if (i < txt.length) {
                setI((prevI) => prevI + 1);
            }
        };

        const interval = setInterval(typeWriter, speed);

        return () => clearInterval(interval);
    }, [i]);

    const isTypingComplete = i !== txt.length;

    return (
        <div className="gradient-text">
            {txt.slice(0, i)}
            {isTypingComplete && <span className="cursor">|</span>}
        </div>
    );
}

const Home = () => {
    const demoRef = useRef(null);
    const downloadRef = useRef(null);

    const [visibleListItems, setVisibleListItems] = useState(0); // To track the visibility of list items

    useEffect(() => {
        // Gradually make each list item visible with a delay
        const timer = setInterval(() => {
            setVisibleListItems((prevVisibleItems) => {
                if (prevVisibleItems < 5) {
                    return prevVisibleItems + 1; // Increment the number of visible items
                } else {
                    clearInterval(timer);
                    return prevVisibleItems;
                }
            });
        }, 300); // Adjust the delay between items here (300ms)

        return () => clearInterval(timer);
    }, []);
    
    const handleShowDemo = () => {
        demoRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleDownloadRef = () => {
        downloadRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    const [animateButtons, setAnimateButtons] = useState(false);
    useEffect(() => {
        if (visibleListItems === 5) {
            setAnimateButtons(true);
        }
    }, [visibleListItems]);

    return (
        <>
            <Helmet>
                {/* Page Title */}
                <title>Home - Tensor Pilot</title>

                {/* Meta Description for SEO */}
                <meta name="description" content="Explore Tensor Pilot AI Drone Software for cutting-edge solutions in autonomous flying, real-time object detection, and advanced data analytics. Perfect for drones in enterprise, education, and retail sectors." />

                {/* SEO Keywords */}
                <meta name="keywords" content="Tensor Pilot, AI Drone Software, Autonomous Flying, Real-Time Object Detection, Drone Analytics, Enterprise Drone Solutions, AI Drone Technology, Drone Education, Drone Retail, Human Pose Detection Drones, Object Tracking Drones" />

                {/* Open Graph Meta Tags for Social Media */}
                <meta property="og:title" content="Home - Tensor Pilot" />
                <meta property="og:description" content="Discover Tensor Pilot AI Drone Software with advanced features for autonomous flying, surveillance, and real-time analytics. Ideal for enterprise, education, and retail drones." />
                <meta property="og:url" content="https://www.aidronesoftware.com/" />
                <meta property="og:site_name" content="Tensor Pilot AI Drone Software" />
                <meta property="og:image" content={Logo} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Home - Tensor Pilot an AI Drone Software" />
                <meta name="twitter:description" content="Tensor Pilot AI Drone Software provides advanced AI solutions for autonomous flying, surveillance, and data analytics, designed for enterprise, education, and retail drones." />
                <meta name="twitter:image" content={Logo} />

                {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            
            <script src="https://js.storylane.io/js/v1/storylane.js"></script>

            <NavBarMain />
            <Container className="col-lg-8 col-md-10 col-12  home-container">

                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <h1 className="gradient-text welcome-title">
                            <TypingEffect />
                        </h1>

                        {/* <div className="home-subheader pl-3 mb-4">
                            <h3 className="text-muted ">
                                A Drone Flight Control Software powered by AI.
                            </h3>
                        </div> */}

                        <div className="home-subheader pl-1 mt-4">
                            <ul className="list-unstyled">
                                <li className={`align-items-center my-2 ${visibleListItems >= 1 ? 'fade-in delay-1' : 'hidden'}`}>
                                <FontAwesomeIcon icon={faDownload} className="welcome-icons" />
                                    <span className="ml-3 text-muted list-text">Win & MacOS drone control software</span>
                                </li>
                                <li className={`align-items-center my-2 ${visibleListItems >= 2 ? 'fade-in delay-2' : 'hidden'}`}>
                                    <FontAwesomeIcon icon={faRocket} className="welcome-icons" />
                                    <span className="ml-3 text-muted list-text">AI boosted flight capabilities</span>
                                </li>
                                <li className={`align-items-center my-2 ${visibleListItems >= 3 ? 'fade-in delay-3' : 'hidden'}`}>
                                    <FontAwesomeIcon icon={faToolbox} className="welcome-icons" />
                                    <span className="ml-3 text-muted list-text">Works out of box for DJI Tello Drones</span>
                                </li>
                                <li className={`align-items-center my-2 ${visibleListItems >= 4 ? 'fade-in delay-4' : 'hidden'}`}>
                                    <FontAwesomeIcon icon={faCogs} className="welcome-icons" />
                                    <span className="ml-3 text-muted list-text">
                                        Custom flight controller integration
                                    </span>
                                </li>
                                <li className={`align-items-center my-2 ${visibleListItems >= 5 ? 'fade-in delay-5' : 'hidden'}`}>
                                    <FontAwesomeIcon icon={faBrain} className="welcome-icons" />
                                    <span className="ml-3 text-muted list-text">Vision AI for video analytics & insights</span>
                                </li>
                            </ul>
                        </div>
                            

                        <div className="custom-buttons-container my-4">
                            <Button 
                                variant="outline-primary fade-in delay-6"
                                className={`btn btn-light btn-lg border-1 full-width custom-download-button mx-2 my-2 ${animateButtons ? 'fade-in delay-6' : 'hidden'}`} 
                                onClick={handleDownloadRef}

                            >
                                <b>
                                <FontAwesomeIcon icon={['fab', 'windows']} />  <FontAwesomeIcon icon={['fab', 'apple']} /> Free Download 
                                </b>
                            </Button>
                            
                            <Button 
                                className={`btn btn-lg center mx-2 my-2 hide-on-mobile full-width ${animateButtons ? 'fade-in delay-7' : 'hidden'}`} 
                                variant='outline-primary'
                                aria-label="Download for Apple"
                                onClick={handleShowDemo}
                            >
                                <b>Try demo </b>
                            </Button>
                        </div>
                    </Col>

                        

                    <Col lg={12} md={12} sm={12} className="my-3 text-center" ref={demoRef}>
                        <center>
                            <div className="iframe-storylane-box">
                                <iframe
                                    src="https://app.storylane.io/demo/abhyadrrxvil"
                                    name="sl-embed"
                                    allow="fullscreen"
                                    className="sl-demo iframe-storylane"
                                    style={{ border: 'none', overflow: 'hidden' }} 
                                />

                            </div>
                        </center>
                    </Col>
                </Row>

                
                <Row>
                    <div>
                        <AIFeaturesDisplay />

                        <div ref={downloadRef}>
                          <PublicDownload />
                        </div>        
                        
                        <CinematographyFeatures />    
                        
                                
                        <YoutubeVideoSection 
                            url="https://youtu.be/_2UGojywVe0"
                        />
                    </div>
                </Row>
                
                
                <Row className="justify-content-center">
                    <div>
                        <DroneControlOptions />
                        
                        <UserTypesSection />
                        <FAQ />
                        <CallToActionSection 
                            handleDownloadRef={handleDownloadRef}
                        />
                    </div>
                </Row>        
            </Container>
        </>
    )
}

export default Home;
